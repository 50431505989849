import React, { useState } from "react";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import PendingIcon from "@mui/icons-material/Pending";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import "./PlaidModal.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const PlaidStatusModal = ({
  status, // one of value ["pending", "success", "error"]
  onClose,
  onAction,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleAction = () => {
    if (onAction) {
      onAction();
    }
    handleClose();
  };

  // Determine button text based on status
  const getButtonText = () => {
    switch (status) {
      case "success":
        return "Access Program";
      case "error":
        return "Contact Sales";
      default:
        return "Close";
    }
  };

  return (
    <BootstrapDialog
      aria-labelledby="plaid-integration-modal"
      className="plaid-integration-dialog"
      open={open}
    >
      <DialogTitle
        sx={{ m: 0, p: 3 }}
        className={`plaid-integration-modal-dialog-title ${status ? "justify-content-center" : ""}`}
      >
        {status === "pending" && (
          <PendingIcon className="plaid-integration-blue" />
        )}

        {status === "success" && (
          <CheckCircleIcon className="plaid-integration-green" />
        )}

        {status === "error" && <CancelIcon className="plaid-integration-red" />}

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16, padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="plaid-integration-modal-dialog-content">
          <div className="plaid-integration-status">
            <div className="plaid-integration-status-title">
              {status === "pending" && "Payment In Progress"}
              {status === "success" && "Payment Successful"}
              {status === "error" && "Payment Failed"}
            </div>
            <div className="plaid-integration-status-detail">
              {status === "pending" ? (
                <>
                  Your transfer is being processed.
                  <br />
                  We&apos;ll notify you when it&apos;s confirmed.
                </>
              ) : status === "success" ? (
                <>
                  Your payment has been processed successfully.
                  <br />
                  You can now access the Platinum Program.
                </>
              ) : (
                <>
                  There was an issue processing your transfer.
                  <br />
                  Please contact us to try again.
                </>
              )}
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          className="plaid-integration-modal-action-button"
          onClick={
            status !== "pending" && onAction ? handleAction : handleClose
          }
        >
          {getButtonText()}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default PlaidStatusModal;
