import React, { useEffect, useMemo } from "react";
import { Route, useHistory } from "react-router-dom";
import PublicLayout from "../components/common/Layout/PublicLayout";
import { useAuth } from "../contexts/Auth";
import { useCookies } from "react-cookie";

// Public Route prevents access to pages that has the property "protected" set, if the user is authenticated already.
const PublicRoute = ({ component: Component, layout: Layout, ...rest }) => {
  const {
    authUser,
    authToken,
    loading,
    userDataLoading,
    canSeeInternal,
    hasWealthSeriesAtlas,
    hasWealthSeriesTitan,
    hasWealthSeriesEverest,
    hasOptionsAcademy,
    userData,
    hasPlatinumProgram,
    hasOptionsMentorshipAdvanced,
    hasOptionsMentorshipIntermediate,
    hasOptionsMentorshipStarter,
    hasEmerald,
    fetchUser,
  } = useAuth();
  const history = useHistory();
  const lastLocation = rest.location.state && rest.location.state.from.pathname;
  const [cookiesReact] = useCookies(["access_token"]);

  // Calculate redirect path directly instead of relying on canSeeInternal from context
  const redirectPath = useMemo(() => {
    if (!userData || loading || userDataLoading) return null;

    // Determine if user can see internal directly
    let userCanSeeInternal = false;

    // Only check if userData is fully loaded
    if (
      userData &&
      userData.feature_flags &&
      Object.keys(userData.feature_flags).length > 0
    ) {
      // Check for mentorship/program access
      if (
        hasPlatinumProgram ||
        hasOptionsMentorshipAdvanced ||
        hasOptionsMentorshipIntermediate ||
        hasOptionsMentorshipStarter ||
        hasEmerald
      ) {
        userCanSeeInternal = true;
      }
      // Check for default feature flag
      else if (userData.feature_flags.default?.active) {
        userCanSeeInternal = true;
      }
      // Check for TradeGPT premium
      else if (userData.feature_flags.tradegpt_premium?.active) {
        userCanSeeInternal = true;
      }
      // Check for Terminal Plus
      else if (userData.feature_flags.terminal_plus?.active) {
        userCanSeeInternal = true;
      }
    }

    if (lastLocation && lastLocation !== "/signout") {
      return lastLocation;
    } else if (hasWealthSeriesEverest) {
      return "/wealth-series/everest";
    } else if (hasWealthSeriesTitan) {
      return "/wealth-series/titan";
    } else if (hasWealthSeriesAtlas) {
      return "/wealth-series/atlas";
    } else if (userCanSeeInternal) {
      // Give precedence to internal access
      return "/home/Intraday/Auto/Up";
    } else if (hasOptionsAcademy) {
      // Only redirect to options academy if user doesn't have internal access
      return "/options-academy/courses/fundamentals";
    } else {
      return "/products";
    }
  }, [
    userData,
    loading,
    userDataLoading,
    lastLocation,
    hasWealthSeriesEverest,
    hasWealthSeriesTitan,
    hasWealthSeriesAtlas,
    hasOptionsAcademy,
    hasPlatinumProgram,
    hasOptionsMentorshipAdvanced,
    hasOptionsMentorshipIntermediate,
    hasOptionsMentorshipStarter,
    hasEmerald,
  ]);

  // Only redirect when we have a valid redirectPath
  useEffect(() => {
    if (authUser && authToken && redirectPath && rest.type === "protected") {
      history.push(redirectPath);
    }
  }, [
    authUser,
    authToken,
    redirectPath,
    history,
    rest.type,
    canSeeInternal,
    loading,
    userDataLoading,
  ]);

  useEffect(() => {
    if (cookiesReact["access_token"] && rest.type === "mobile") {
      fetchUser();
    }
  }, [cookiesReact["access_token"], fetchUser, rest.type]);

  return (
    <Route
      {...rest}
      render={(props) =>
        rest.customLayout ? (
          <Layout {...rest}>
            <Component {...props} />
          </Layout>
        ) : (
          <PublicLayout {...rest}>
            <Component {...props} />
          </PublicLayout>
        )
      }
    />
  );
};

export default PublicRoute;
