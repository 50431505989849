import React from "react";
import {
  showSuccess,
  showError,
  showInfo,
  showPending,
  showAlertWithButton,
} from "../../utils/alertUtils";

import PlaidAlert from "./components/PlaidAlert";
import PlaidInvoiceModal from "./components/PlaidInvoiceModal";
import PlaidStatusModal from "./components/PlaidStatusModal";
import Button from "@mui/material/Button";

const RuslanComponent = () => {
  const showGlobalSuccessAlert = () => {
    showSuccess(
      "Payment Successful",
      "Your payment has been processed successfully.",
    );
  };

  const showGlobalErrorAlert = () => {
    showError(
      "Payment Failed",
      "There was an issue processing your payment. Please try again.",
    );
  };

  const showGlobalInfoAlert = () => {
    showInfo(
      "Payment Required",
      "Please complete your payment to access this feature.",
    );
  };

  const showGlobalPendingAlert = () => {
    showPending(
      "Payment Processing",
      "Your payment is being processed. This may take a few moments.",
      { autoHide: false },
    );
  };

  const showButtonAlert = () => {
    showAlertWithButton(
      "Action Required",
      "Please complete your payment to continue.",
      "Make Payment",
      () => console.log("Payment button clicked"),
      "primary",
      "info",
    );
  };

  return (
    <div
      className="ruslan-component"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
      }}
    >
      <div style={{ marginBottom: "20px" }}>
        <h2>Global Alert System Demo</h2>
        <div
          style={{
            display: "flex",
            gap: "10px",
            marginBottom: "20px",
            flexWrap: "wrap",
          }}
        >
          <Button
            variant="contained"
            color="success"
            onClick={showGlobalSuccessAlert}
          >
            Show Success Alert
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={showGlobalErrorAlert}
          >
            Show Error Alert
          </Button>
          <Button
            variant="contained"
            color="info"
            onClick={showGlobalInfoAlert}
          >
            Show Info Alert
          </Button>
          <Button
            variant="contained"
            color="warning"
            onClick={showGlobalPendingAlert}
          >
            Show Pending Alert
          </Button>
          <Button variant="contained" color="primary" onClick={showButtonAlert}>
            Show Alert with Button
          </Button>
        </div>
      </div>

      <h2>Local Alert Components</h2>
      <PlaidAlert
        title="Payment Initiated."
        subtitle="Your transfer is being processed. We'll notify you when it's confirmed."
        type="primary"
        iconType="pending"
      />

      <PlaidAlert
        title="Payment Failed."
        subtitle="There was an issue processing your transfer. Please check your bank details and try again."
        type="error"
        iconType="error"
      />

      <PlaidAlert
        title="Payment Cancelled."
        subtitle="Your transfer has been cancelled. Please try again or contact support."
        type="error"
        iconType="error"
      />

      <PlaidAlert
        title="Partial Payment Received."
        subtitle="Your partial payment has been processed successfully. Please note that full payment is required before you can access the product."
        type="success"
        iconType="success"
      />

      <PlaidAlert
        title="Proceed to make payment for {Emerald Income Alerts}."
        type="primary"
        iconType="info"
        buttonText="Make Payment"
        onButtonClick={() => {
          console.log("Make Payment Clicked");
        }}
      />

      <PlaidAlert
        title="Your trial has expired. Please purchase to continue."
        type="error"
        iconType="info"
      />

      <PlaidInvoiceModal
        productName="Emerald Income Alerts"
        totalAmount={28462}
        amountToPay={14000}
      />

      <PlaidStatusModal status="pending" />
    </div>
  );
};

export default RuslanComponent;
