// Action Types
export const SHOW_GLOBAL_ALERT = "GLOBAL_ALERT/SHOW";
export const HIDE_GLOBAL_ALERT = "GLOBAL_ALERT/HIDE";
export const CLEAR_ALL_ALERTS = "GLOBAL_ALERT/CLEAR_ALL";

// Initial State
const INIT_STATE = {
  alerts: [],
};

// Action Creators
export const showGlobalAlert = (alert) => ({
  type: SHOW_GLOBAL_ALERT,
  payload: alert,
});

export const hideGlobalAlert = (id) => ({
  type: HIDE_GLOBAL_ALERT,
  payload: { id },
});

export const clearAllAlerts = () => ({
  type: CLEAR_ALL_ALERTS,
});

// Helper function to generate unique IDs
const generateId = () =>
  `alert_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SHOW_GLOBAL_ALERT: {
      const newAlert = {
        id: generateId(),
        ...action.payload,
        timestamp: Date.now(),
      };
      return {
        ...state,
        alerts: [...state.alerts, newAlert],
      };
    }
    case HIDE_GLOBAL_ALERT: {
      return {
        ...state,
        alerts: state.alerts.filter((alert) => alert.id !== action.payload.id),
      };
    }
    case CLEAR_ALL_ALERTS: {
      return {
        ...state,
        alerts: [],
      };
    }
    default:
      return state;
  }
};

export default reducer;

// Utility functions for common alert types
export const showSuccessAlert = (title, subtitle = "", options = {}) => {
  return showGlobalAlert({
    title,
    subtitle,
    type: "success",
    iconType: "success",
    ...options,
  });
};

export const showErrorAlert = (title, subtitle = "", options = {}) => {
  return showGlobalAlert({
    title,
    subtitle,
    type: "error",
    iconType: "error",
    ...options,
  });
};

export const showInfoAlert = (title, subtitle = "", options = {}) => {
  return showGlobalAlert({
    title,
    subtitle,
    type: "primary",
    iconType: "info",
    ...options,
  });
};

export const showPendingAlert = (title, subtitle = "", options = {}) => {
  return showGlobalAlert({
    title,
    subtitle,
    type: "primary",
    iconType: "pending",
    ...options,
  });
};
