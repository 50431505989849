import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import LayoutContext from "../LayoutContext";
import "./sidebarStyles.scss";
import "./../../../homePage/home-page.css";
import "./styles.css";
import { useAuth } from "../../../../contexts/Auth";
import useOnClickOutside from "../../../../hooks/useOnClickOutside";
import HomeHeader from "../../../homePage/HomeHeader";
import { makeStyles } from "@material-ui/core";
import clsx from "clsx";
// import CopyrightFooter from "../CopyrightFooter";
import Sidebar from "./Sidebar";
import { analytics } from "../../../../firebase/firebase";
import { useMedia } from "react-media";
import { useCookies } from "react-cookie";
import { MobileSidebarProvider } from "./MobileSidebarContext";
import GlobalAlerts from "../../../GlobalAlerts/GlobalAlerts";
// import firebase from "firebase";

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.background,
  },
  appBannerLight: {
    fontSize: "16px",
    backgroundColor: "#bbc1ca !important",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    position: "sticky",
    top: 0,
    zIndex: 99999999,
  },
  appBannerDark: {
    fontSize: "16px",
    backgroundColor: "#3c3c4a !important",
    color: "#fff",
    textAlign: "center",
    padding: "10px",
    position: "sticky",
    top: 0,
    zIndex: 99999999,
  },
}));
const mediaQuery = {
  isTablet: "screen and (max-width: 1400px)",
};
const PrivateLayout = (props) => {
  const { children, name, from, noLayout, type, liveStream, themeMode } = props;
  const classes = useStyles();
  const { authUser } = useAuth();
  const [isActivePopUp, setIsActivePopUp] = useState(false);
  const [isDark, setIsDark] = useState(false);
  const location = useLocation();
  const userPopupRef = useRef();
  const env = process.env["REACT_APP_ENV"];
  const ssoAtStr = `sso_at_${env}`;
  const sapStr = `sso_at_present_${env}`;
  const [cookies] = useCookies([ssoAtStr, sapStr]);
  const { isMobile, isTablet } = useMedia({
    queries: mediaQuery,
  });
  const history = useHistory();
  const userPopupTriggerRef = useRef();
  const userPopupTriggerMbRef = useRef();
  useOnClickOutside(
    userPopupRef,
    () => {
      if (isActivePopUp) {
        setIsActivePopUp(false);
      }
    },
    isTablet || isMobile ? userPopupTriggerMbRef : userPopupTriggerRef,
  );

  useEffect(() => {
    setIsActivePopUp(false);
    analytics.logEvent(location.pathname + " PAGE_VISITED");
  }, [location]);

  useEffect(() => {
    if (!cookies[sapStr] && !!authUser) {
      history.push("/signout");
    }
  }, [cookies, authUser]);

  if (noLayout) {
    return children;
  }

  const onChangeDarkMode = (checked) => {
    setIsDark(checked);
  };

  return (
    <LayoutContext.Provider value={{ isActivePopUp, setIsActivePopUp }}>
      <MobileSidebarProvider>
        <div className="layout-wrapper">
          <Sidebar />

          <div id="submenu-container"></div>

          <div
            id="layout-wrapper"
            className={clsx(classes.background, `content-in-layout`)}
          >
            <div className={`main-content-${themeMode} main-content`}>
              {type !== "internal" && (
                <HomeHeader
                  isDark={isDark}
                  onChangeDarkMode={onChangeDarkMode}
                  name={name}
                  from={from}
                />
              )}
              {/* Global Alerts placed right after the header */}
              <GlobalAlerts />
              <div
                className={clsx(
                  classes.background,
                  "site-layout-background sidebar-layout-content",
                )}
              >
                <div
                  className={`container${liveStream ? "-xl-live" : ""} sidebar-content-container`}
                >
                  {children}
                </div>
              </div>

              <div
                ref={userPopupRef}
                className={`user-profile-popup ${isActivePopUp ? "open" : "close"}`}
              >
                <CSSTransition
                  classNames="user-popup-transtion"
                  timeout={500}
                  unmountOnExit
                  in={isActivePopUp}
                >
                  {children}
                </CSSTransition>
              </div>
            </div>
          </div>
        </div>
        {/* <CopyrightFooter /> */}
      </MobileSidebarProvider>
    </LayoutContext.Provider>
  );
};

PrivateLayout.defaultProps = {
  fixedPage: "",
  pageCenter: "",
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(PrivateLayout);
