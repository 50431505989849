import React, { useEffect, useMemo, useState, useRef } from "react";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { Modal } from "@material-ui/core";

import "./EmeraldSignup.scss";
import {
  EmeraldBenefitCheckIcon,
  EmeraldBigGreenIcon,
  DarkEmeraldBigGreenIcon,
  EmeraldBigTextIcon,
  IncomeAlertsTextIcon,
} from "../common/Icon";
import ContactForm from "./ContactForm";
import moment from "moment";
import { useAuth } from "../../contexts/Auth";
import { useHistory } from "react-router-dom";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";
import { usePlaidLink } from "react-plaid-link";
import { isNull } from "lodash";
import PlaidStatusModal from "../Plaid/components/PlaidStatusModal";
import PlaidInvoiceModal from "../Plaid/components/PlaidInvoiceModal";
import {
  initiateTransfer,
  completeTransfer,
  fetchTransfers,
  fetchBills,
  getTransferData,
} from "../../appRedux/ducks/plaid";
import {
  hideAlert,
  showPaymentStatusModal,
  PAYMENT_STATUS,
} from "../../utils/alertUtils";

const EmeraldSignup = ({
  themeMode,
  userSettings,
  plaidState,
  initTransfer,
  completeTransfer,
  fetchTransfers,
  fetchBills,
}) => {
  const [open, setOpen] = useState(false);
  const { loading, hasEmerald, user, userData, fetchUser } = useAuth();
  const history = useHistory();
  const name = userData?.email || user?.email;

  // Plaid states
  const alertIdRef = useRef(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusModalProps, setStatusModalProps] = useState({});
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceModalProps, setInvoiceModalProps] = useState({});
  const redirectTimerRef = useRef(null);

  const currentBill = useMemo(
    () =>
      plaidState?.bills?.find(
        (bill) =>
          bill.product_type_slug === "emerald" && isNull(bill.transfer_status),
      ),
    [plaidState?.bills],
  );

  const failedBill = useMemo(
    () =>
      plaidState?.bills?.find(
        (bill) =>
          bill.product_type_slug === "emerald" &&
          (bill.transfer_status === "failed" ||
            bill.transfer_status === "cancelled" ||
            bill.transfer_status === "returned"),
      ),
    [plaidState?.bills],
  );

  // Detect partial payment scenario where one bill is paid but another remains unpaid
  const hasPartialPayment = useMemo(() => {
    if (!plaidState?.bills) return false;

    // Group bills by invoice_id
    const billsByInvoice = {};
    plaidState.bills.forEach((bill) => {
      if (bill.product_type_slug === "emerald") {
        if (!billsByInvoice[bill.invoice_id]) {
          billsByInvoice[bill.invoice_id] = [];
        }
        billsByInvoice[bill.invoice_id].push(bill);
      }
    });

    // Check for invoices with both settled and null transfer_status bills
    return Object.values(billsByInvoice).some(
      (bills) =>
        bills.length > 1 &&
        bills.some(
          (bill) =>
            bill.transfer_status === "settled" ||
            bill.transfer_status === "funds_available",
        ) &&
        bills.some((bill) => bill.transfer_status === null),
    );
  }, [plaidState?.bills]);

  const isPendingPayment = useMemo(
    () =>
      plaidState?.bills?.some(
        (bill) =>
          bill.product_type_slug === "emerald" &&
          (bill.transfer_status === "pending" ||
            bill.transfer_status === "posted"),
      ),
    [plaidState?.bills],
  );

  const isSuccessfulPayment = useMemo(
    () =>
      plaidState?.bills?.some(
        (bill) =>
          bill.product_type_slug === "emerald" &&
          (bill.transfer_status === "settled" ||
            bill.transfer_status === "funds_available"),
      ),
    [plaidState?.bills],
  );

  const isFailedPayment = useMemo(
    () =>
      plaidState?.bills?.some(
        (bill) =>
          bill.product_type_slug === "emerald" &&
          (bill.transfer_status === "failed" ||
            bill.transfer_status === "cancelled" ||
            bill.transfer_status === "returned"),
      ),
    [plaidState?.bills],
  );

  // Plaid success callback
  async function onPlaidSuccess(public_token) {
    // Use the Redux action to complete the transfer
    const transferData = getTransferData();
    completeTransfer(public_token, transferData.transfer_id);
    await fetchUser();
  }

  // Initialize Plaid Link
  const { open: openPlaidLink, ready } = usePlaidLink({
    token: plaidState?.transfer?.link,
    onSuccess: onPlaidSuccess,
  });

  // Effect to automatically open Plaid when token is available and ready
  useEffect(() => {
    if (plaidState?.transfer && ready) {
      openPlaidLink();
    }
  }, [plaidState?.transfer, ready, openPlaidLink]);

  // Fetch accounts and bill data on component mount
  useEffect(() => {
    if (fetchTransfers && fetchBills) {
      fetchTransfers();
      fetchBills();
    }
  }, [fetchTransfers, fetchBills]);

  // Effect to show appropriate payment status alerts and modals
  useEffect(() => {
    // Clean up previous alert if exists
    if (alertIdRef.current) {
      hideAlert(alertIdRef.current);
      alertIdRef.current = null;
    }

    // Reset modal states
    setShowStatusModal(false);
    setShowInvoiceModal(false);

    // If there's a current bill (transfer_status is null), don't show any alerts
    // This takes precedence over failed payment alerts
    if (currentBill) {
      return;
    }

    // Show appropriate alert and modal based on payment status
    if (isPendingPayment) {
      const { alertId, modalProps } = showPaymentStatusModal(
        PAYMENT_STATUS.PENDING,
        {
          title: "Payment in Progress",
          subtitle:
            "Payment Initiated. Your transfer is being processed. We'll notify you when it's confirmed.",
          autoHide: true,
        },
      );
      alertIdRef.current = alertId;
      setStatusModalProps({
        ...modalProps,
        status: "pending",
      });
      setShowStatusModal(true);
    } else if (isSuccessfulPayment && !hasPartialPayment) {
      const { alertId } = showPaymentStatusModal(PAYMENT_STATUS.SUCCESS, {
        title: "Payment Successful",
        subtitle:
          "Your payment has been processed successfully. Redirecting you in 5 seconds...",
      });
      alertIdRef.current = alertId;

      // Clear any existing redirect timer
      if (redirectTimerRef.current) {
        clearTimeout(redirectTimerRef.current);
      }

      // Set up a new redirect timer for 5 seconds
      redirectTimerRef.current = setTimeout(() => {
        // First fetch latest user data to ensure feature flags are up to date
        fetchUser().then(() => {
          history.push("/emerald/tutorials");
        });
      }, 5000);
    } else if (hasPartialPayment) {
      // For partial payment scenario, show a different message
      const { alertId } = showPaymentStatusModal(PAYMENT_STATUS.PENDING, {
        title: "Partial Payment Received",
        subtitle:
          "We've received your initial payment. Please complete the remaining balance to access the program.",
        autoHide: true,
      });
      alertIdRef.current = alertId;
    } else if (isFailedPayment) {
      const { alertId, modalProps } = showPaymentStatusModal(
        PAYMENT_STATUS.ERROR,
        {
          title: "Payment Failed",
          subtitle:
            "Payment Failed. There was an issue processing your transfer. Please contact us to try again.",
        },
      );
      alertIdRef.current = alertId;
      setStatusModalProps({
        ...modalProps,
        status: "error",
      });
      setShowStatusModal(true);
    }

    // Clean up the alert and timer when the component unmounts
    return () => {
      if (alertIdRef.current) {
        hideAlert(alertIdRef.current);
      }

      if (redirectTimerRef.current) {
        clearTimeout(redirectTimerRef.current);
      }
    };
  }, [
    isPendingPayment,
    isSuccessfulPayment,
    isFailedPayment,
    hasPartialPayment,
    currentBill,
    history,
    fetchUser,
  ]);

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (loading) return;
    if (hasEmerald) {
      history.push("/emerald/tutorials");
    }
  }, [loading, hasEmerald, history]);

  const hasSubmitted = useMemo(() => {
    const submitDate = userSettings?.emeraldContact;
    const weekSinceSubmit = moment(submitDate).add(1, "week");
    const today = moment();
    // Return true if today is before weekSinceSubmit (within a week since submitDate)
    return submitDate ? today.isBefore(weekSinceSubmit) : false;
  }, [userSettings, loading]);

  const color = useMemo(() => {
    return themeMode === "light" ? "#000" : "#fff";
  }, [themeMode]);

  const fillColor = useMemo(() => {
    return themeMode === "light" ? "#3EB489" : "#65C3A1";
  }, [themeMode]);

  // Function to determine the button text and state based on payment status
  const getButtonState = () => {
    // Always prioritize showing Pay button if there's a current bill
    if (currentBill) {
      return {
        text: "Pay",
        disabled: false,
        onClick: () => {
          setInvoiceModalProps({
            productName: "Emerald Income Alerts",
            totalAmount: currentBill.sales_price,
            amountToPay: currentBill.transfer_amount,
          });
          setShowInvoiceModal(true);
        },
        className: "",
      };
    }

    if (isPendingPayment) {
      return {
        text: "Payment Processing",
        disabled: true,
        className: "processing",
      };
    } else if (isSuccessfulPayment && !hasPartialPayment) {
      return {
        text: "Access Program",
        disabled: false,
        onClick: () => history.push("/emerald/tutorials"),
        className: "success",
      };
    } else if (isFailedPayment) {
      return {
        text: "Contact Sales",
        disabled: false,
        onClick: openModal,
        className: "error",
      };
    } else if (hasPartialPayment) {
      return {
        text: "Pay Balance",
        disabled: false,
        onClick: () => {
          // Show invoice modal directly for the remaining bill
          const remainingBill = currentBill;
          if (remainingBill) {
            setInvoiceModalProps({
              productName: "Emerald Income Alerts",
              totalAmount: remainingBill.sales_price,
              amountToPay: remainingBill.transfer_amount,
              isPartialPayment: true,
              paidAmount: remainingBill.paid,
            });
            setShowInvoiceModal(true);
          }
        },
        className: "partial-payment",
      };
    } else if (hasSubmitted) {
      return {
        text: "Access Requested",
        disabled: true,
        className: "",
      };
    } else {
      return {
        text: "Contact Us For Pricing",
        disabled: false,
        onClick: openModal,
        className: "",
      };
    }
  };

  return (
    <div className="emerald-signup-page">
      <div className="emerald-signup__header">
        {themeMode === "light" ? (
          <EmeraldBigGreenIcon />
        ) : (
          <DarkEmeraldBigGreenIcon />
        )}
        <div className="emerald-signup__header_text">
          <EmeraldBigTextIcon fill={color} />
          <IncomeAlertsTextIcon fill={color} />
        </div>
      </div>

      <div className="emerald-signup__content">
        <div className="emerald-signup__content_title">
          Premium Trading signals for Elite Traders
        </div>
        <div className="emerald-signup__content_subtitle">
          Go beyond rudimentary alerts. Emerald Income Alerts leverages AI and
          short interest data to deliver premium trading signals and in-depth
          training on elite-level strategies.
        </div>
        <div className="emerald-signup__content_benefits">
          <div className="emerald-benefit-row">
            <div className="emerald-signup__content_benefit">
              <EmeraldBenefitCheckIcon fill={fillColor} color="#fff" />
              <span className="emerald-signup__content_benefit_name">
                Short Interest
              </span>
            </div>
            <div className="emerald-signup__content_benefit">
              <EmeraldBenefitCheckIcon fill={fillColor} color="#fff" />
              <span className="emerald-signup__content_benefit_name">
                Artificial Intelligence
              </span>
            </div>
          </div>

          <div className="emerald-benefit-row">
            <div className="emerald-signup__content_benefit">
              <EmeraldBenefitCheckIcon fill={fillColor} color="#fff" />
              <span className="emerald-signup__content_benefit_name">
                Premium Support
              </span>
            </div>
            <div className="emerald-signup__content_benefit">
              <EmeraldBenefitCheckIcon fill={fillColor} color="#fff" />
              <span className="emerald-signup__content_benefit_name">
                Real-Time Alerts for Trading
              </span>
            </div>
          </div>
        </div>
        <div className="emerald-price">
          <div className="emerald-price__value">$15,000</div>
          <div className="emerald-price__symbol">USD</div>
        </div>
      </div>
      <div className="gain-access-container">
        <div className="gain-access-text">
          Gain Access to Options Mentorship
        </div>
        <div className="gain-access-list">
          <ul className="gain-access-list__items">
            <li>16 Options Mentorship Emerald sessions</li>
            <li>8 Options Mentorship Advanced sessions</li>
            <li>8 Options Mentorship Intermediate sessions</li>
          </ul>
        </div>
      </div>

      <div className="emerald-signup__footer">
        {loading ? (
          <BeautifulSkeleton
            sx={{ position: "relative", bottom: 22 }}
            height={100}
          />
        ) : (
          <>
            {(() => {
              const buttonState = getButtonState();
              return (
                <>
                  <Button
                    className={`emerald-get-access-button nowrap ${buttonState.className}`}
                    variant="contained"
                    onClick={buttonState.onClick}
                    disabled={buttonState.disabled}
                  >
                    {buttonState.text}
                  </Button>
                  <div className="emerald-get-access-button__shadow" />
                </>
              );
            })()}
          </>
        )}
      </div>

      <Modal open={open}>
        <ContactForm
          product_type="EMERALD INCOME ALERTS"
          onClose={handleClose}
        />
      </Modal>

      {/* Payment Status Modal */}
      {showStatusModal && (
        <PlaidStatusModal
          status={statusModalProps.status}
          onClose={() => setShowStatusModal(false)}
          onAction={() => {
            if (statusModalProps.status === "success") {
              fetchUser();
            } else if (statusModalProps.status === "error") {
              openModal();
            }
            setShowStatusModal(false);
          }}
        />
      )}

      {/* Payment Invoice Modal */}
      {showInvoiceModal && (
        <PlaidInvoiceModal
          productName={invoiceModalProps.productName}
          totalAmount={invoiceModalProps.totalAmount}
          amountToPay={invoiceModalProps.amountToPay}
          isPartialPayment={invoiceModalProps.isPartialPayment}
          paidAmount={invoiceModalProps.paidAmount}
          onClose={() => setShowInvoiceModal(false)}
          onAction={() => {
            // Only use current bill for payment
            if (currentBill) {
              initTransfer(currentBill.bill_id, name, null);
              setShowInvoiceModal(false);
            }
          }}
        />
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
  plaidState: state.plaid,
});

const dispatchToProps = (dispatch) => ({
  initTransfer: (billId, legalName, accountId) =>
    dispatch(initiateTransfer(billId, legalName, accountId)),
  completeTransfer: (publicToken, transferId) =>
    dispatch(completeTransfer(publicToken, transferId)),
  fetchTransfers: () => dispatch(fetchTransfers()),
  fetchBills: () => dispatch(fetchBills()),
});

export default connect(stateToProps, dispatchToProps)(EmeraldSignup);
