import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { makeStyles } from "@material-ui/core";
import history from "../TradingView/history";
import clsx from "clsx";
import { connect } from "react-redux";
import { useAuth } from "../../contexts/Auth";
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: "0!important",
    },
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.primary.customSearch2 + ` !important`,
    },
    "& .MuiInputBase-fullWidth": {
      backgroundColor: theme.palette.primary.customSearch2 + ` !important`,
      border: `1px solid ${theme.palette.primary.customSearchBorderColor} !important`,
    },
    "& .MuiOutlinedInput-input": {
      color: theme.palette.primary.seachFieldTextColor + `!important`,
      fontFamily: "Inter",
      position: "relative",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "24px",
      height: "44px",
      position: "relative",
      width: "100%",
      paddingRight: "35px!important",
    },
    "&  .MuiAutocomplete-endAdornment": {
      display: "none",
      visibility: "none",
    },
    "& label.Mui-focused": {
      color: "grey",
    },
    "& label": {
      display: "none",
      position: "relative",
      top: "32px",
      color: "grey",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
  themed: {
    background: theme.palette.primary.chartBackground,
    color: theme.palette.primary.text,
  },
}));

function TickerSearch({
  options,
  loading,
  setTicker,
  symbol,
  setSymbol,
  setUserSettings,
  company,
  themeMode,
}) {
  const classes = useStyles();
  const { userData } = useAuth();
  const [value, setValue] = useState({
    ticker: symbol,
    name: "",
    type: "",
    score: 0,
  });
  const setDefaultTicker = (defaultOptionChainTicker) => {
    if (userData) {
      setUserSettings({
        ...userData?.userSettings,
        defaultOptionChainTicker,
      });
    }
  };
  return (
    <Autocomplete
      disabled={company}
      className={classes.root}
      style={{ position: "relative", zIndex: 100, minWidth: 250 }}
      id="highlights-demo"
      options={options}
      loading={loading}
      disablePortal={true}
      noOptionsText={"No Matching Tickers Found"}
      getOptionLabel={(option) => option.ticker + " " + option.name}
      // getOptionLabel={(option) => option.ticker}
      isOptionEqualToValue={(option, value) => option.ticker === value.ticker}
      defaultValue={value}
      onChange={(e, valueT) => {
        if (valueT) {
          setTicker(valueT.ticker);
          setValue(valueT);
          setDefaultTicker(valueT.ticker);
          history.push(`/option-chain/${valueT.ticker}`);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          className={clsx(classes.root, "option-scanner-search-input")}
          onChange={(e) => {
            setSymbol(e.target.value);
          }}
          value={symbol}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                <span style={{ position: "relative", left: "20px" }}>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.4121 14.4121L20 20"
                      stroke={themeMode === "light" ? "#2C2C2C" : "white"}
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                    <path
                      d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z"
                      stroke={themeMode === "light" ? "#2C2C2C" : "white"}
                      strokeWidth="2"
                      strokeLinecap="square"
                    />
                  </svg>

                  {params.InputProps.endAdornment}
                </span>
              </React.Fragment>
            ),
          }}
          style={{ border: "none !important" }}
          label="Search Tickers"
          margin="normal"
        />
      )}
      renderOption={(props, option, { inputValue }) => {
        const matches = match(option.ticker + " - " + option.name, inputValue);
        const parts = parse(option.ticker + " - " + option.name, matches);
        return (
          <li {...props}>
            <div className="scanner-option">
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{
                    fontWeight: part.highlight ? 700 : 400,
                  }}
                >
                  {part.text}
                </span>
              ))}
            </div>
          </li>
        );
      }}
    />
  );
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

export default connect(stateToProps, null)(TickerSearch);
