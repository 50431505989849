import React, { useEffect, useMemo, useState, useRef } from "react";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import image from "../../../assets/images/trading.png";
import SignUpHint from "./SignUpHint/SignUpHint";
import "./PlatinumProgramSignUp.scss";
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import { useAuth } from "../../../contexts/Auth";
import { useHistory } from "react-router-dom";
import { useMedia } from "react-media";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import leftArrow from "../PastVideos/leftArrow.png";
import closeIcon from "../PastVideos/Minimize.png";
import { makeStyles } from "@material-ui/core";
import IconButton from "@mui/material/IconButton";
import { contactFormLoad } from "../../../appRedux/ducks/contactForm";
import ContactForm from "../../EmeraldSignup/ContactForm";
import { Modal } from "@material-ui/core";
import moment from "moment";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";
import { usePlaidLink } from "react-plaid-link";
import {
  initiateTransfer,
  completeTransfer,
  fetchTransfers,
  fetchBills,
  getTransferData,
} from "../../../appRedux/ducks/plaid";
import { isNull } from "lodash";
import PlaidStatusModal from "../../Plaid/components/PlaidStatusModal";
import PlaidInvoiceModal from "../../Plaid/components/PlaidInvoiceModal";
import {
  hideAlert,
  showPaymentStatusModal,
  PAYMENT_STATUS,
} from "../../../utils/alertUtils";

const useStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: `${theme.palette.primary.background} !important`,
    zIndex: 1000000,
  },
}));

const mediaQuery = {
  isMobile: "screen and (max-width: 799px)",
};

const PlatinumProgramSignUp = ({
  userSettings,
  plaidState,
  initTransfer,
  completeTransfer,
  fetchTransfers,
  fetchBills,
}) => {
  const { user, userData, fetchUser, loading, hasPlatinumProgram } = useAuth();
  const [open, setOpen] = useState(false);
  const [product_type, setProduct_type] = useState("");
  const name = userData?.email || user?.email;
  const currentBill = plaidState.bills.find(
    (bill) =>
      (bill.product_type_slug === "platinum" ||
        bill.product_title === "3 Month Platinum") &&
      isNull(bill.transfer_status),
  );
  const failedBill = useMemo(
    () =>
      plaidState.bills.find(
        (bill) =>
          (bill.product_type_slug === "platinum" ||
            bill.product_title === "3 Month Platinum") &&
          (bill.transfer_status === "failed" ||
            bill.transfer_status === "cancelled" ||
            bill.transfer_status === "returned"),
      ),
    [plaidState.bills],
  );

  // Detect partial payment scenario where one bill is paid but another remains unpaid
  const hasPartialPayment = useMemo(() => {
    // Group bills by invoice_id
    const billsByInvoice = {};
    plaidState.bills.forEach((bill) => {
      if (
        bill.product_type_slug === "platinum" ||
        bill.product_title === "3 Month Platinum"
      ) {
        if (!billsByInvoice[bill.invoice_id]) {
          billsByInvoice[bill.invoice_id] = [];
        }
        billsByInvoice[bill.invoice_id].push(bill);
      }
    });

    // Check for invoices with both settled and null transfer_status bills
    return Object.values(billsByInvoice).some(
      (bills) =>
        bills.length > 1 &&
        bills.some(
          (bill) =>
            bill.transfer_status === "settled" ||
            bill.transfer_status === "funds_available",
        ) &&
        bills.some((bill) => bill.transfer_status === null),
    );
  }, [plaidState.bills]);

  const isPendingPayment = useMemo(
    () =>
      plaidState.bills.some(
        (bill) =>
          (bill.product_type_slug === "platinum" ||
            bill.product_title === "3 Month Platinum") &&
          (bill.transfer_status === "pending" ||
            bill.transfer_status === "posted"),
      ),
    [plaidState.bills],
  );

  const isSuccessfulPayment = useMemo(
    () =>
      plaidState.bills.some(
        (bill) =>
          (bill.product_type_slug === "platinum" ||
            bill.product_title === "3 Month Platinum") &&
          (bill.transfer_status === "settled" ||
            bill.transfer_status === "funds_available"),
      ),
    [plaidState.bills],
  );

  const isFailedPayment = useMemo(
    () =>
      plaidState.bills.some(
        (bill) =>
          (bill.product_type_slug === "platinum" ||
            bill.product_title === "3 Month Platinum") &&
          (bill.transfer_status === "failed" ||
            bill.transfer_status === "cancelled" ||
            bill.transfer_status === "returned"),
      ),
    [plaidState.bills],
  );

  // Refs to track modal and alert states
  const alertIdRef = useRef(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusModalProps, setStatusModalProps] = useState({});
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceModalProps, setInvoiceModalProps] = useState({});
  const redirectTimerRef = useRef(null);

  // product types: 'PLATINUM STANDARD', 'OPTIONS MENTORSHIP PLATINUM',
  const openModal = (product) => {
    setProduct_type(product);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Plaid success callback
  async function onPlaidSuccess(public_token) {
    // Use the Redux action to complete the transfer
    const transferData = getTransferData();
    completeTransfer(public_token, transferData.transfer_id);
    await fetchUser();
  }

  // Initialize Plaid Link
  const { open: openPlaidLink, ready } = usePlaidLink({
    token: plaidState.transfer?.link,
    onSuccess: onPlaidSuccess,
  });

  // Effect to automatically open Plaid when token is available and ready
  useEffect(() => {
    if (plaidState.transfer && ready) {
      openPlaidLink();
    }
  }, [plaidState.transfer, ready, openPlaidLink]);

  // Fetch accounts and bill data on component mount
  useEffect(() => {
    fetchTransfers();
    fetchBills();
  }, [fetchTransfers, fetchBills]);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const classes = useStyles();
  const { isMobile } = useMedia({
    queries: mediaQuery,
  });

  const { hasSubmittedStandard, hasSubmittedOm } = useMemo(() => {
    const standardSubmitDate = userSettings?.platinumStandardContact;
    const omSubmitDate = userSettings?.omPlatinumContact;
    const weekSinceStandardSubmit = moment(standardSubmitDate).add(1, "week");
    const weekSinceOmSubmit = moment(omSubmitDate).add(1, "week");
    const today = moment();
    return {
      hasSubmittedStandard: standardSubmitDate
        ? today.isBefore(weekSinceStandardSubmit)
        : false,
      hasSubmittedOm: omSubmitDate ? today.isBefore(weekSinceOmSubmit) : false,
    };
  }, [userSettings, loading]);

  const history = useHistory();

  useEffect(() => {
    if (!loading && hasPlatinumProgram) {
      history.push("/platinum-program/livestream");
    }
  }, [hasPlatinumProgram, loading, history]);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const list = () => (
    <Box
      sx={{ width: 390 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <div className="find-out-more">
        <IconButton
          onClick={() => setDrawerOpen(false)}
          aria-label="delete"
          size="small"
        >
          <img src={closeIcon} className="w-70px" alt="close" />
        </IconButton>
      </div>
      <SignUpHint />
    </Box>
  );

  // Effect to show appropriate payment status alerts and modals
  useEffect(() => {
    // Clean up previous alert if exists
    if (alertIdRef.current) {
      hideAlert(alertIdRef.current);
      alertIdRef.current = null;
    }

    // Reset modal states
    setShowStatusModal(false);
    setShowInvoiceModal(false);

    // Show appropriate alert and modal based on payment status
    if (isPendingPayment) {
      const { alertId, modalProps } = showPaymentStatusModal(
        PAYMENT_STATUS.PENDING,
        {
          title: "Payment in Progress",
          subtitle:
            "Payment Initiated. Your transfer is being processed. We'll notify you when it's confirmed.",
          autoHide: true,
        },
      );
      alertIdRef.current = alertId;
      setStatusModalProps({
        ...modalProps,
        status: "pending",
      });
      setShowStatusModal(true);
    } else if (isSuccessfulPayment && !hasPartialPayment) {
      const { alertId } = showPaymentStatusModal(PAYMENT_STATUS.SUCCESS, {
        title: "Payment Successful",
        subtitle:
          "Your payment has been processed successfully. Redirecting you in 5 seconds...",
      });
      alertIdRef.current = alertId;

      // Clear any existing redirect timer
      if (redirectTimerRef.current) {
        clearTimeout(redirectTimerRef.current);
      }

      // Set up a new redirect timer for 5 seconds
      redirectTimerRef.current = setTimeout(() => {
        // First fetch latest user data to ensure feature flags are up to date
        fetchUser().then(() => {
          history.push("/platinum-program/livestream");
        });
      }, 5000);
    } else if (hasPartialPayment) {
      // For partial payment scenario, show a different message
      const { alertId } = showPaymentStatusModal(PAYMENT_STATUS.PENDING, {
        title: "Partial Payment Received",
        subtitle:
          "We've received your initial payment. Please complete the remaining balance to access the program.",
        autoHide: true,
      });
      alertIdRef.current = alertId;
    } else if (isFailedPayment) {
      const { alertId, modalProps } = showPaymentStatusModal(
        PAYMENT_STATUS.ERROR,
        {
          title: "Payment Failed",
          subtitle:
            "Payment Failed. There was an issue processing your transfer. Please contact us to try again.",
        },
      );
      alertIdRef.current = alertId;
      setStatusModalProps({
        ...modalProps,
        status: "error",
      });
      setShowStatusModal(true);
    }

    // Clean up the alert and timer when the component unmounts
    return () => {
      if (alertIdRef.current) {
        hideAlert(alertIdRef.current);
      }

      if (redirectTimerRef.current) {
        clearTimeout(redirectTimerRef.current);
      }
    };
  }, [
    isPendingPayment,
    isSuccessfulPayment,
    isFailedPayment,
    hasPartialPayment,
    currentBill,
    history,
    fetchUser,
  ]);

  // Function to determine the button text and state based on payment status
  const getButtonState = (hasBill, hasSubmitted) => {
    if (isPendingPayment) {
      return {
        text: "Payment Processing",
        disabled: true,
        className: "processing",
      };
    } else if (isSuccessfulPayment && !hasPartialPayment) {
      return {
        text: "Access Program",
        disabled: false,
        onClick: () => history.push("/platinum-program/livestream"),
        className: "success",
      };
    } else if (isFailedPayment) {
      return {
        text: "Contact Sales",
        disabled: false,
        onClick: () => {
          // For 3 Month Platinum, use PLATINUM STANDARD contact form
          if (currentBill && currentBill.product_title === "3 Month Platinum") {
            openModal("PLATINUM STANDARD");
          } else {
            openModal("OPTIONS MENTORSHIP PLATINUM");
          }
        },
        className: "error",
      };
    } else if (hasPartialPayment) {
      return {
        text: "Pay Balance",
        disabled: false,
        onClick: () => {
          // Show invoice modal directly for the remaining bill
          const remainingBill = currentBill;
          if (remainingBill) {
            const productDisplayName =
              remainingBill.product_title === "3 Month Platinum"
                ? "The Platinum Program"
                : "Options Mentorship Platinum";

            setInvoiceModalProps({
              productName: productDisplayName,
              totalAmount: remainingBill.sales_price,
              amountToPay: remainingBill.transfer_amount,
              isPartialPayment: true,
              paidAmount: remainingBill.paid,
            });
            setShowInvoiceModal(true);
          }
        },
        className: "partial-payment",
      };
    } else if (hasBill) {
      return {
        text: "Pay",
        disabled: false,
        onClick: () => {
          const billToUse = currentBill;
          if (billToUse) {
            const productDisplayName =
              billToUse.product_title === "3 Month Platinum"
                ? "The Platinum Program"
                : "Options Mentorship Platinum";

            setInvoiceModalProps({
              productName: productDisplayName,
              totalAmount: billToUse.sales_price,
              amountToPay: billToUse.transfer_amount,
            });
            setShowInvoiceModal(true);
          }
        },
        className: "",
      };
    } else if (hasSubmitted) {
      return {
        text: "Access Requested",
        disabled: true,
        className: "",
      };
    } else {
      return {
        text: hasSubmittedOm ? "Access Requested" : "Contact Us",
        disabled: hasSubmittedOm,
        onClick: () => {
          if (hasBill && hasBill.product_title === "3 Month Platinum") {
            openModal("PLATINUM STANDARD");
          } else {
            openModal("OPTIONS MENTORSHIP PLATINUM");
          }
        },
        className: "",
      };
    }
  };

  return (
    <div className="platinum-program-sign-up">
      <div className="find-out-more">
        {isMobile && (
          <Button
            onClick={() => setDrawerOpen(true)}
            variant="outlined"
            startIcon={
              <img className="left-arrow" src={leftArrow} alt="arrow" />
            }
          >
            Find out more
          </Button>
        )}
        <Drawer
          classes={{ paper: classes.paper }}
          anchor={"right"}
          open={drawerOpen}
          onClose={toggleDrawer(false)}
        >
          {list()}
        </Drawer>
      </div>

      <div className="platinum-program-sign-up-left">
        <div className="platinum-program-sign-up-header">
          <div className="platinum-program-sign-up-title">
            The Platinum Program
          </div>
          <div className="platinum-program-sign-up-info">
            Profitable Option Trading
          </div>
          <div className="platinum-program-sign-up-info-1">Made Easy.</div>
        </div>
        <div className="platinum-program-sign-up-trading-section">
          <div className="platinum-program-sign-up-unlock-text">
            Unlock the Secrets to Successful Trading
          </div>
          <img
            src={image}
            alt="trading"
            className="platinum-program-sign-up-trading"
          />
        </div>

        <div className="platinum-program-sign-up-items">
          <div className="platinum-program-sign-up-item-row">
            <div className="platinum-program-sign-up-item">
              <span className="platinum-program-sign-up-item-icon">
                <CheckIcon />
              </span>{" "}
              Short Interest
            </div>
            <div className="platinum-program-sign-up-item">
              <span className="platinum-program-sign-up-item-icon">
                <CheckIcon />
              </span>{" "}
              Artificial Intelligence
            </div>
          </div>
          <div className="platinum-program-sign-up-item-row">
            <div className="platinum-program-sign-up-item">
              <span className="platinum-program-sign-up-item-icon">
                <CheckIcon />
              </span>{" "}
              Real-Time Alerts for Trading
            </div>
            <div className="platinum-program-sign-up-item">
              <span className="platinum-program-sign-up-item-icon">
                <CheckIcon />
              </span>{" "}
              Premium Support
            </div>
          </div>
        </div>

        <div className="platinum-program-sign-up-price-row">
          <div className="platinum-program-sign-up-price-row-inner">
            <div className="platinum-program-plan">
              <div className="platinum-program-plan-header">Standard</div>
              <div className="platinum-program-plan-content">
                <div className="platinum-program-plan-title">
                  The Platinum
                  <br />
                  Program
                </div>
                <ul className="platinum-program-plan-features">
                  <li className="platinum-program-plan-feature">
                    <span>Tutorials</span>
                  </li>
                  <li className="platinum-program-plan-feature">
                    <span>Daily Live Streaming</span>
                  </li>
                  <li className="platinum-program-plan-feature">
                    <span>Daily Live FAQ sessions</span>
                  </li>
                  <li className="platinum-program-plan-feature">
                    <span>Platinum Signal Alerts</span>
                  </li>
                </ul>

                <div className="platinum-prgoram-plan-price">
                  $2,500 <span className="platinum-small-text">USD</span>
                </div>
                <div className="platinum-prgoram-plan-duration">/ 3 months</div>
                {loading ? (
                  <BeautifulSkeleton className="platinum-program-sign-up-button-small" />
                ) : (
                  <>
                    {(() => {
                      const standardCurrentBill = plaidState.bills.find(
                        (bill) =>
                          bill.product_title === "3 Month Platinum" &&
                          isNull(bill.transfer_status),
                      );

                      const buttonState = getButtonState(
                        standardCurrentBill || false,
                        hasSubmittedStandard,
                      );
                      return (
                        <Button
                          className={`platinum-program-sign-up-button-small nowrap ${buttonState.className}`}
                          onClick={
                            buttonState.onClick ||
                            (() => openModal("PLATINUM STANDARD"))
                          }
                          disabled={buttonState.disabled}
                        >
                          {buttonState.text}
                        </Button>
                      );
                    })()}
                  </>
                )}
              </div>
            </div>

            <div className="platinum-program-plan">
              <div className="platinum-program-plan-header">
                Most Recommended
              </div>
              <div className="platinum-program-plan-content">
                <div className="platinum-program-plan-title">
                  Options Mentorship
                  <br />
                  Platinum
                </div>
                <ul className="platinum-program-plan-features">
                  <li className="platinum-program-plan-feature">
                    <span>Options trading pro-led personalized coaching</span>
                  </li>
                  <li className="platinum-program-plan-feature">
                    <span>Tutorials</span>
                  </li>
                  <li className="platinum-program-plan-feature">
                    <span>Daily Live Streaming</span>
                  </li>
                  <li className="platinum-program-plan-feature">
                    <span>Daily Live FAQ sessions</span>
                  </li>
                  <li className="platinum-program-plan-feature">
                    <span>Platinum Signal Alerts</span>
                  </li>
                </ul>

                <div className="platinum-prgoram-plan-price">
                  $9,995 <span className="platinum-small-text">USD</span>
                </div>
                <div className="platinum-prgoram-plan-duration">
                  / 12 sessions
                </div>
                {loading ? (
                  <BeautifulSkeleton className="platinum-program-sign-up-button-small" />
                ) : (
                  <>
                    {(() => {
                      // Find a bill specific to Options Mentorship Platinum
                      // Only consider bills with product_type_slug "platinum" that are NOT "3 Month Platinum"
                      const omCurrentBill = plaidState.bills.find(
                        (bill) =>
                          bill.product_type_slug === "platinum" &&
                          bill.product_title !== "3 Month Platinum" &&
                          isNull(bill.transfer_status),
                      );

                      const buttonState = getButtonState(
                        omCurrentBill || false,
                        hasSubmittedOm,
                      );
                      return (
                        <Button
                          className={`platinum-program-sign-up-button-small nowrap ${buttonState.className}`}
                          onClick={
                            buttonState.onClick ||
                            (() => openModal("OPTIONS MENTORSHIP PLATINUM"))
                          }
                          disabled={buttonState.disabled}
                        >
                          {buttonState.text}
                        </Button>
                      );
                    })()}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="platinum-program-sign-up-footer">
          <div className="platinum-program-sign-up-footer-left">
            <div className="platinum-program-sign-up-footer-title">
              Access To Cutting Edge Option Strategies
            </div>
            <div className="platinum-program-sign-up-footer-description">
              This page is exclusively designed for our registered members and
              provides access to a wealth of information and resources that are
              not available to the general public.
              <br />
              <br />
              Don&apos;t miss out on this incredible opportunity to access our
              private content. Sign up now to start exploring everything that
              what our{" "}
              <span className="platinum-program-gradient-text">
                Platinum Program
              </span>{" "}
              💎 has to offer.
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
        <div className="platinum-program-sign-up-right">
          <SignUpHint />
        </div>
      )}

      <Modal open={open}>
        <ContactForm product_type={product_type} onClose={handleClose} />
      </Modal>

      {/* Payment Status Modal */}
      {showStatusModal && (
        <PlaidStatusModal
          status={statusModalProps.status}
          onClose={() => setShowStatusModal(false)}
          onAction={() => {
            if (statusModalProps.status === "success") {
              fetchUser();
            } else if (statusModalProps.status === "error") {
              openModal("OPTIONS MENTORSHIP PLATINUM");
            }
            setShowStatusModal(false);
          }}
        />
      )}

      {/* Payment Invoice Modal */}
      {showInvoiceModal && (
        <PlaidInvoiceModal
          productName={invoiceModalProps.productName}
          totalAmount={invoiceModalProps.totalAmount}
          amountToPay={invoiceModalProps.amountToPay}
          isPartialPayment={invoiceModalProps.isPartialPayment}
          paidAmount={invoiceModalProps.paidAmount}
          onClose={() => setShowInvoiceModal(false)}
          onAction={() => {
            // Initiate the transfer using the failed bill's ID if available
            const billToUse = currentBill;
            if (billToUse) {
              initTransfer(billToUse.bill_id, name, null);
              setShowInvoiceModal(false);
            }
          }}
        />
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
  plaidState: state.plaid,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender, fetch) =>
    dispatch(addUserSettingsLoad(settings, firstRender, fetch)),
  submitContactForm: (formData) => dispatch(contactFormLoad(formData)),
  initTransfer: (billId, legalName, accountId) =>
    dispatch(initiateTransfer(billId, legalName, accountId)),
  completeTransfer: (publicToken, transferId) =>
    dispatch(completeTransfer(publicToken, transferId)),
  fetchTransfers: () => dispatch(fetchTransfers()),
  fetchBills: () => dispatch(fetchBills()),
});

export default connect(stateToProps, dispatchToProps)(PlatinumProgramSignUp);
