import * as React from "react";
import { useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../appRedux/ducks/userSettings";
import { releaseNotesVersion } from "../../contexts/Auth/provider";
import { useAuth } from "../../contexts/Auth";
import moment from "moment";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function ReleaseNotesModal({ setUserSettings }) {
  const history = useHistory();
  const { userData } = useAuth();
  const [open, setOpen] = React.useState(true);

  const handleReadmore = () => {
    history.push("/release-notes");
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  React.useEffect(() => {
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  React.useEffect(() => {
    if (userData) {
      setUserSettings({
        ...userData?.userSettings,
        releaseNotesVersion,
      });
    }
  }, [userData]);

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        className="release-notes-modal"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} className="release-note-dialog-title">
          Wealth Series: Wealth Series Lite is Now Available
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
        <div className="release-note-dialog-subtitle">
          Release Note for v{releaseNotesVersion} on{" "}
          {moment.utc("2025-03-10T00:00:00.000Z").format("MMM D, YYYY")}
        </div>
        <DialogContent>
          <div className="release-note-dialog-content">
            Wealth Series Lite Now Available
            <ul>
              <li>
                Wealth Series Lite combines our AI stock picker model with a
                user-customizable portfolio that gamifies into a leaderboard
                stage across other subscribers.
              </li>
              <li>
                Gain exclusive access to our AI stock picks, refreshed on a
                biweekly basis.
              </li>
              <li>Contact our sales team to find out more.</li>
            </ul>
          </div>
        </DialogContent>
        <DialogActions className="release-note-dialog-actions">
          <Button
            variant="outlined"
            onClick={handleReadmore}
            className="release-note-dialog-button"
          >
            Read more
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(ReleaseNotesModal);
