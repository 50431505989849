import React from "react";
import { useLocation } from "react-router-dom";

function MobileVimeoPlayer() {
  const location = useLocation();

  // Function to extract videoId from URL
  const getVideoIdFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return {
      videoId: queryParams.get("videoId"),
      live: queryParams.get("live") === "true",
    };
  };
  const { videoId, live } = getVideoIdFromUrl();
  return (
    <div className="mobile-vimeo-player">
      {
        <>
          <iframe
            src={`https://vimeo.com/event/${videoId}/embed`}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            title="Vimeo video player"
          ></iframe>
          {live && (
            <iframe
              src={`https://vimeo.com/event/${videoId}/chat`}
              className="market-analysis-event-chat"
              title="market-analysis-chat"
            ></iframe>
          )}
        </>
      }
    </div>
  );
}

export default MobileVimeoPlayer;
