import React, { useEffect, useMemo, useRef, useState } from "react";

import "./WealthSeriesSignUp.scss";
import WSGlowLogo from "../../assets/images/wealth-series-signup/logo.png";
import { Button, Modal } from "@material-ui/core";
import AtlasDarkLogo from "../../assets/images/wealth-series-signup/atlas-logo-dark.png";
import TitanDarkLogo from "../../assets/images/wealth-series-signup/titan-logo-dark.png";
import EverestDarkLogo from "../../assets/images/wealth-series-signup/everest-logo-dark.png";
import { connect } from "react-redux";
import { useAuth } from "../../contexts/Auth";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";
import moment from "moment";
import ContactForm from "../EmeraldSignup/ContactForm";
import { usePlaidLink } from "react-plaid-link";
import { isNull } from "lodash";
import PlaidStatusModal from "../Plaid/components/PlaidStatusModal";
import PlaidInvoiceModal from "../Plaid/components/PlaidInvoiceModal";
import {
  initiateTransfer,
  completeTransfer,
  fetchTransfers,
  fetchBills,
  getTransferData,
} from "../../appRedux/ducks/plaid";
import {
  hideAlert,
  showPaymentStatusModal,
  PAYMENT_STATUS,
} from "../../utils/alertUtils";
import { useHistory } from "react-router-dom";

// Product slug constants
const WEALTH_SERIES_SLUGS = [
  "wealth_series_everest",
  "wealth_series_titan",
  "wealth_series_atlas",
];

const WealthSeriesSignUp = ({
  userSettings,
  plaidState,
  themeMode,
  initTransfer,
  completeTransfer,
  fetchTransfers,
  fetchBills,
}) => {
  const {
    loading,
    user,
    userData,
    fetchUser,
    hasWealthSeries,
    hasWealthSeriesAtlas,
    hasWealthSeriesTitan,
    hasWealthSeriesEverest,
  } = useAuth();

  const [open, setOpen] = useState(false);
  const [isInViewport, setIsInViewport] = useState(false);
  const targetRef = useRef(null);
  const history = useHistory();
  // Plaid states
  const alertIdRef = useRef(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusModalProps, setStatusModalProps] = useState({});
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceModalProps, setInvoiceModalProps] = useState({});
  const name = userData?.email || user?.email;
  const redirectTimerRef = useRef(null);
  const fromSidebar = useMemo(() => {
    return new URLSearchParams(window.location.search).get("from-sidebar");
  }, []);
  // Find any bill for the wealth series products
  const availableProductBills = useMemo(() => {
    if (!plaidState?.bills) return {};

    const bills = {};
    WEALTH_SERIES_SLUGS.forEach((slug) => {
      bills[slug] = plaidState.bills.filter(
        (bill) => bill.product_type_slug === slug,
      );
    });

    return bills;
  }, [plaidState?.bills]);

  // Find current (unpaid) bill for any wealth series product
  const currentBill = useMemo(() => {
    if (!plaidState?.bills) return null;

    return plaidState.bills.find(
      (bill) =>
        WEALTH_SERIES_SLUGS.includes(bill.product_type_slug) &&
        isNull(bill.transfer_status),
    );
  }, [plaidState?.bills]);

  // Find failed bill for any wealth series product
  const failedBill = useMemo(() => {
    if (!plaidState?.bills) return null;

    return plaidState.bills.find(
      (bill) =>
        WEALTH_SERIES_SLUGS.includes(bill.product_type_slug) &&
        (bill.transfer_status === "failed" ||
          bill.transfer_status === "cancelled" ||
          bill.transfer_status === "returned"),
    );
  }, [plaidState?.bills]);

  // Check if any payment is pending for any wealth series product
  const isPendingPayment = useMemo(
    () =>
      plaidState?.bills?.some(
        (bill) =>
          WEALTH_SERIES_SLUGS.includes(bill.product_type_slug) &&
          (bill.transfer_status === "pending" ||
            bill.transfer_status === "posted"),
      ),
    [plaidState?.bills],
  );

  // Check if any payment is successful for any wealth series product
  const isSuccessfulPayment = useMemo(
    () =>
      plaidState?.bills?.some(
        (bill) =>
          WEALTH_SERIES_SLUGS.includes(bill.product_type_slug) &&
          (bill.transfer_status === "settled" ||
            bill.transfer_status === "funds_available"),
      ),
    [plaidState?.bills],
  );

  // Check if any payment failed for any wealth series product
  const isFailedPayment = useMemo(
    () =>
      plaidState?.bills?.some(
        (bill) =>
          WEALTH_SERIES_SLUGS.includes(bill.product_type_slug) &&
          (bill.transfer_status === "failed" ||
            bill.transfer_status === "cancelled" ||
            bill.transfer_status === "returned"),
      ),
    [plaidState?.bills],
  );

  // Determine which product has an active bill
  const activeProductSlug = useMemo(() => {
    if (currentBill) return currentBill.product_type_slug;
    if (failedBill) return failedBill.product_type_slug;

    // If no current or failed bill, check if any product has bills
    for (const slug of WEALTH_SERIES_SLUGS) {
      if (availableProductBills[slug]?.length > 0) {
        return slug;
      }
    }

    return null;
  }, [currentBill, failedBill, availableProductBills]);

  // Function to get class name for product card based on active product
  const getProductCardClassName = (productSlug) => {
    if (!activeProductSlug) return "";

    return productSlug === activeProductSlug ? "" : "ws-signup-tier--inactive";
  };

  // Plaid success callback
  async function onPlaidSuccess(public_token) {
    // Use the Redux action to complete the transfer
    const transferData = getTransferData();
    completeTransfer(public_token, transferData.transfer_id);

    // Fetch user data to update the active tiers
    await fetchUser();
  }

  // Initialize Plaid Link
  const { open: openPlaidLink, ready } = usePlaidLink({
    token: plaidState?.transfer?.link,
    onSuccess: onPlaidSuccess,
  });

  // Effect to automatically open Plaid when token is available and ready
  useEffect(() => {
    if (plaidState?.transfer && ready) {
      openPlaidLink();
    }
  }, [plaidState?.transfer, ready, openPlaidLink]);

  // Fetch accounts and bill data on component mount
  useEffect(() => {
    if (fetchTransfers && fetchBills) {
      fetchTransfers();
      fetchBills();
    }
  }, [fetchTransfers, fetchBills]);

  const checkIfInViewport = () => {
    const rect = targetRef.current.getBoundingClientRect();
    setIsInViewport(
      rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= window.innerHeight &&
        rect.right <= window.innerWidth,
    );
  };

  // Function to determine which page to redirect to based on active tiers
  const getRedirectPath = () => {
    // Check which tiers the user has access to and redirect to the appropriate page
    if (hasWealthSeriesEverest) {
      return "/wealth-series/everest";
    } else if (hasWealthSeriesTitan) {
      return "/wealth-series/titan";
    } else if (hasWealthSeriesAtlas) {
      return "/wealth-series/atlas";
    }

    // Fallback: try to determine from the payment data if user data isn't updated yet
    const paidProductSlug = plaidState?.bills?.find(
      (bill) =>
        WEALTH_SERIES_SLUGS.includes(bill.product_type_slug) &&
        (bill.transfer_status === "settled" ||
          bill.transfer_status === "funds_available"),
    )?.product_type_slug;

    if (paidProductSlug) {
      const series = paidProductSlug.split("_").pop();
      return `/wealth-series/${series}`;
    }

    // Default fallback
    return "/wealth-series-signup";
  };

  // Effect to show appropriate payment status alerts and modals
  useEffect(() => {
    // Clean up previous alert if exists
    if (alertIdRef.current) {
      hideAlert(alertIdRef.current);
      alertIdRef.current = null;
    }

    // Reset modal states
    setShowStatusModal(false);
    setShowInvoiceModal(false);

    // If there's a current bill (transfer_status is null), don't show any alerts
    // This takes precedence over failed payment alerts
    if (currentBill) {
      return;
    }

    // Show appropriate alert and modal based on payment status
    if (isPendingPayment) {
      const { alertId, modalProps } = showPaymentStatusModal(
        PAYMENT_STATUS.PENDING,
        {
          title: "Payment in Progress",
          subtitle:
            "Payment Initiated. Your transfer is being processed. We'll notify you when it's confirmed.",
          autoHide: true,
        },
      );
      alertIdRef.current = alertId;
      setStatusModalProps({
        ...modalProps,
        status: "pending",
      });
      setShowStatusModal(true);
    } else if (isSuccessfulPayment) {
      const { alertId } = showPaymentStatusModal(PAYMENT_STATUS.SUCCESS, {
        title: "Payment Successful",
        subtitle: `Your payment has been processed successfully. ${!fromSidebar ? "Redirecting you in 5 seconds..." : ""}`,
      });
      alertIdRef.current = alertId;

      // Clear any existing redirect timer
      if (redirectTimerRef.current) {
        clearTimeout(redirectTimerRef.current);
      }
      if (!fromSidebar) {
        // Set up a new redirect timer for 5 seconds
        redirectTimerRef.current = setTimeout(() => {
          // Get the appropriate redirect path based on active tiers
          // First fetch latest user data to ensure feature flags are up to date
          fetchUser().then(() => {
            const redirectPath = getRedirectPath();
            history.push(redirectPath);
          });
        }, 5000);
      }
    } else if (isFailedPayment) {
      const { alertId, modalProps } = showPaymentStatusModal(
        PAYMENT_STATUS.ERROR,
        {
          title: "Payment Failed",
          subtitle:
            "Payment Failed. There was an issue processing your transfer. Please contact us to try again.",
        },
      );
      alertIdRef.current = alertId;
      setStatusModalProps({
        ...modalProps,
        status: "error",
      });
      setShowStatusModal(true);
    }

    // Clean up the alert and timer when the component unmounts or when dependency changes
    return () => {
      if (alertIdRef.current) {
        hideAlert(alertIdRef.current);
      }

      if (redirectTimerRef.current) {
        clearTimeout(redirectTimerRef.current);
      }
    };
  }, [
    isPendingPayment,
    isSuccessfulPayment,
    isFailedPayment,
    currentBill,
    plaidState?.bills,
  ]);

  // Redirect if user already has access
  useEffect(() => {
    if (loading) return;

    if (hasWealthSeries) {
      const redirectPath = getRedirectPath();
      if (!fromSidebar) {
        history.push(redirectPath);
      }
    }
  }, [
    loading,
    hasWealthSeries,
    hasWealthSeriesAtlas,
    hasWealthSeriesTitan,
    hasWealthSeriesEverest,
    fromSidebar,
  ]);

  useEffect(() => {
    // Check if the element is in viewport on initial render
    checkIfInViewport();

    // Add a scroll event listener to check on scroll
    window.addEventListener("scroll", checkIfInViewport);
    window.addEventListener("resize", checkIfInViewport);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener("scroll", checkIfInViewport);
      window.removeEventListener("resize", checkIfInViewport);
    };
  }, []);

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const hasSubmitted = useMemo(() => {
    const submitDate = userSettings?.wsContact;
    const weekSinceSubmit = moment(submitDate).add(1, "day");
    const today = moment();
    // Return true if today is before weekSinceSubmit (within a week since submitDate)
    return submitDate ? today.isBefore(weekSinceSubmit) : false;
  }, [userSettings, loading]);

  // Function to determine the button text and state based on payment status
  const getButtonState = () => {
    // Always prioritize showing Pay button if there's a current bill
    if (currentBill) {
      return {
        text: "Pay",
        disabled: false,
        onClick: () => {
          setInvoiceModalProps({
            productName: "Wealth Series",
            totalAmount: currentBill.sales_price,
            amountToPay: currentBill.transfer_amount,
          });
          setShowInvoiceModal(true);
        },
        className: "",
      };
    }

    if (isPendingPayment) {
      return {
        text: "Payment Processing",
        disabled: true,
        className: "processing",
      };
    } else if (isSuccessfulPayment && !fromSidebar) {
      return {
        text: "Access Program",
        disabled: false,
        onClick: () => {
          const redirectPath = getRedirectPath();
          history.push(redirectPath);
        },
        className: "success",
      };
    } else if (isFailedPayment) {
      return {
        text: "Contact Sales",
        disabled: false,
        onClick: openModal,
        className: "error",
      };
    } else if (hasSubmitted) {
      return {
        text: "Access Requested",
        disabled: true,
        className: "",
      };
    } else {
      return {
        text: "Schedule Call",
        disabled: false,
        onClick: openModal,
        className: "",
      };
    }
  };

  return (
    <div className="ws-sign-up">
      <div className="ws-sign-up__content">
        <img
          src={WSGlowLogo}
          alt="Wealth Series Logo"
          className="ws-glow-logo"
        />
        <div className="ws-signup-description">
          Leverage on cutting-edge AI technology coupled with advanced
          diversification techniques. Unlock maximum performance and
          resilience—every portfolio strategically diversifies across sectors
          and narratives, providing you with more robust protection and growth
          potential.
        </div>
        <div className="ws-signup-button-row">
          <div className="ws-signup-divider" />
          {loading ? (
            <BeautifulSkeleton
              className="no-transform-scale"
              width={300}
              height={50}
            />
          ) : (
            <>
              {(() => {
                const buttonState = getButtonState();
                return (
                  <Button
                    variant="contained"
                    color="primary"
                    className={`ws-signup-button ${isInViewport ? "ws-signup-button--visible" : ""} ${buttonState.className || ""}`}
                    onClick={buttonState.onClick || openModal}
                    disabled={buttonState.disabled || false}
                  >
                    {buttonState.text ||
                      (hasSubmitted ? "Access Requested" : "Schedule Call")}
                  </Button>
                );
              })()}
            </>
          )}
        </div>
        <div className="ws-signup-tiers">
          <div
            className={`ws-signup-tier ws-signup-tier--atlas ${getProductCardClassName("wealth_series_atlas")}`}
          >
            <div className="ws-signup-tier-header">
              <img
                src={AtlasDarkLogo}
                alt="Atlas Dark Logo"
                className="ws-signup-tier-logo"
              />
              <div className="ws-signup-tier-description">
                Powerful portfolios for steady and scalable success.
              </div>
            </div>
            <div className="ws-signup-tier-divider" />
            <div className="ws-signup-tier-benefits">
              <div className="ws-signup-tier-benefit-title">Benefits</div>
              <div className="ws-signup-tier-benefit">Growth Focused</div>
              <div className="ws-signup-tier-benefit">
                3 Portfolio Strategies
              </div>
            </div>
          </div>
          <div
            className={`ws-signup-tier ws-signup-tier--titan ${getProductCardClassName("wealth_series_titan")}`}
          >
            <div className="ws-signup-tier-header">
              <img
                src={TitanDarkLogo}
                alt="Titan Dark Logo"
                className="ws-signup-tier-logo"
              />
              <div className="ws-signup-tier-description">
                Optimized portfolios designed to protect & amplify your wealth.
              </div>
            </div>
            <div className="ws-signup-tier-divider" />
            <div className="ws-signup-tier-benefits">
              <div className="ws-signup-tier-benefit-title">Benefits</div>
              <div className="ws-signup-tier-benefit">Balance Focused</div>
              <div className="ws-signup-tier-benefit">
                3 Portfolio Strategies
              </div>
            </div>
          </div>
          <div
            className={`ws-signup-tier ws-signup-tier--everest ${getProductCardClassName("wealth_series_everest")}`}
          >
            <div className="ws-signup-tier-header">
              <img
                src={EverestDarkLogo}
                alt="Everest Dark Logo"
                className="ws-signup-tier-logo"
              />
              <div className="ws-signup-tier-description">
                An exclusive solution for unparalleled wealth management.
              </div>
            </div>
            <div className="ws-signup-tier-divider" />
            <div className="ws-signup-tier-benefits" ref={targetRef}>
              <div className="ws-signup-tier-benefit-title">Benefits</div>
              <div className="ws-signup-tier-benefit">
                Wealth Management Focused
              </div>
              <div className="ws-signup-tier-benefit">
                5 Portfolio Strategies
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="ws-sign-up__disclaimer">
        This website provides AI-powered insights for informational purposes
        only. Please use at your own discretion, and consult a qualified
        financial advisor for personalized guidance.
      </div>

      <Modal open={open}>
        <ContactForm product_type="WEALTH SERIES" onClose={handleClose} />
      </Modal>

      {/* Payment Status Modal */}
      {showStatusModal && (
        <PlaidStatusModal
          status={statusModalProps.status}
          onClose={() => setShowStatusModal(false)}
          onAction={() => {
            if (statusModalProps.status === "success") {
              fetchUser();
            } else if (statusModalProps.status === "error") {
              openModal();
            }
            setShowStatusModal(false);
          }}
        />
      )}

      {/* Payment Invoice Modal */}
      {showInvoiceModal && (
        <PlaidInvoiceModal
          productName={`Wealth Series${currentBill ? ` ${currentBill.product_type_slug.split("_").pop().charAt(0).toUpperCase() + currentBill.product_type_slug.split("_").pop().slice(1)}` : ""}`}
          totalAmount={invoiceModalProps.totalAmount}
          amountToPay={invoiceModalProps.amountToPay}
          onClose={() => setShowInvoiceModal(false)}
          onAction={() => {
            // Only use current bill for payment
            if (currentBill) {
              initTransfer(currentBill.bill_id, name, null);
              setShowInvoiceModal(false);
            }
          }}
        />
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  userSettings: state.userSettings.userSettings,
  plaidState: state.plaid,
  themeMode: state.themeMode.themeMode,
});

const dispatchToProps = (dispatch) => ({
  initTransfer: (billId, legalName, accountId) =>
    dispatch(initiateTransfer(billId, legalName, accountId)),
  completeTransfer: (publicToken, transferId) =>
    dispatch(completeTransfer(publicToken, transferId)),
  fetchTransfers: () => dispatch(fetchTransfers()),
  fetchBills: () => dispatch(fetchBills()),
});

export default connect(stateToProps, dispatchToProps)(WealthSeriesSignUp);
