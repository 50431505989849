import React from "react";

const TradingToolsIcon = ({
  color = "rgba(0, 0, 0, 0.56)",
  size = 24,
  active = false,
  isDark = false,
}) => {
  // Handle color logic based on state
  const iconColor = active
    ? "#007bff" // Active color
    : isDark
      ? "rgba(255, 255, 255, 0.8)" // Dark theme
      : color; // Default color

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.6704 18.1699L16.9504 13.4499C16.4704 12.9699 15.9604 12.8599 15.3704 12.8599L12.8304 15.3999C12.8304 15.9899 12.9404 16.5099 13.4204 16.9799L18.1404 21.6999C18.5304 22.09 19.1604 22.09 19.5504 21.6999L21.6704 19.5799C22.0604 19.1999 22.0604 18.5599 21.6704 18.1699Z"
        fill={iconColor}
      />
      <path
        d="M16.6305 9.48995C17.0205 9.87995 17.6505 9.87995 18.0405 9.48995L18.7505 8.77994L20.8705 10.8999C22.0405 9.72995 22.0405 7.82995 20.8705 6.65995L18.0405 3.82995C17.6505 3.43995 17.0205 3.43995 16.6305 3.82995L15.9205 4.53995V1.99995C15.9205 1.37995 15.1605 1.04995 14.7105 1.49995L12.1705 4.03995C11.7205 4.48995 12.0505 5.24995 12.6705 5.24995H15.2105L14.5005 5.95995C14.1105 6.34995 14.1105 6.97995 14.5005 7.36995L14.8505 7.71994L11.9605 10.6099L7.85055 6.47995V5.47995C7.85055 5.20995 7.74055 4.95995 7.56055 4.76995L5.54055 2.73995C5.15055 2.34995 4.52055 2.34995 4.13055 2.73995L2.71055 4.15995C2.32055 4.54995 2.32055 5.17995 2.71055 5.56995L4.73055 7.59995C4.92055 7.78995 5.17055 7.88995 5.44055 7.88995H6.44055L10.5705 12.0199L9.72055 12.8699H8.42055C7.89055 12.8699 7.38055 13.0799 7.01055 13.4599L2.29055 18.1799C1.90055 18.5699 1.90055 19.1999 2.29055 19.5899L4.41055 21.7099C4.80055 22.0999 5.43055 22.0999 5.82055 21.7099L10.5405 16.9899C10.9205 16.6099 11.1305 16.1099 11.1305 15.5799V14.2899L16.2805 9.13995L16.6305 9.48995Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default TradingToolsIcon;
