import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import "./sidebarStyles.scss";
import { connect } from "react-redux";
import { useMedia } from "react-media";
import { useHistory, useLocation } from "react-router-dom";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SubmenuProductCard from "./SubmenuProductCard";
import SimpleSubmenu from "./SimpleSubmenu";
import ThemeToggle from "./ThemeToggle";
import { useMobileSidebar } from "./MobileSidebarContext";
import { useAuth } from "../../../../contexts/Auth";
import atlas from "../../../../assets/sidebar/atlas.png";
import titan from "../../../../assets/sidebar/titan.png";
import lite from "../../../../assets/sidebar/lite.png";
import everest from "../../../../assets/sidebar/everest.png";
import optionsAcademy from "../../../../assets/sidebar/options-academy.png";
import platinum from "../../../../assets/sidebar/platinum.png";
import emerald from "../../../../assets/sidebar/emerald.png";
import optionsMentorship from "../../../../assets/sidebar/options-mentorship.png";
import TALogoDark from "../../../OptionsAcademy/assets/ta-logo-dark.png";
import TALogoLight from "../../../OptionsAcademy/assets/ta-logo-white.png";
import { IconButton } from "@material-ui/core";
import {
  DashboardIcon,
  LiveSessionsIcon,
  TradingToolsIcon,
  WealthSeriesIcon,
  OptionsIcon,
  ProductsIcon,
  SettingsIcon,
  SupportIcon,
  CollapseIcon,
} from "./icons";

// Import dark theme versions of product images
import atlasDark from "../../../../assets/sidebar/atlas-dark.png";
import titanDark from "../../../../assets/sidebar/titan-dark.png";
import liteDark from "../../../../assets/sidebar/lite-dark.png";
import everestDark from "../../../../assets/sidebar/everest-dark.png";
import platinumDark from "../../../../assets/sidebar/platinum-dark.png";
import emeraldDark from "../../../../assets/sidebar/emerald-dark.png";
import optionsMentorshipDark from "../../../../assets/sidebar/options-mentorship-dark.png";
import { liveChatSetter } from "../../../../appRedux/ducks/hideLiveChat";
import { addUserSettingsLoad } from "../../../../appRedux/ducks/userSettings";
export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;
const mediaQuery = {
  isMobile: "screen and (max-width: 991px)",
};

const Sidebar = ({
  setHideChat,
  setUserSettings,
  themeMode,
  userSettings,
  defaultTickersSettings,
  liveChatExpanded,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const { isMobile } = useMedia({ queries: mediaQuery });
  const history = useHistory();
  const location = useLocation();
  const pathName = location.pathname;
  const {
    mobileMenuOpen,
    activeSubmenu,
    closeMobileMenu,
    openSubmenu,
    closeSubmenu,
  } = useMobileSidebar();
  const {
    minPremium = 3500,
    minContracts = 0,
    expWithinMin = 1,
    expWithinMax = 730,
  } = userSettings;

  // Get user permissions from Auth context
  const {
    userData,
    canSeeInternal,
    emeraldPath,
    platinumProgramPath,
    optionsAcademyPath,
    optionsMentorshipPath,
    hasWealthSeriesAtlas,
    hasWealthSeriesTitan,
    hasWealthSeriesEverest,
    hasWealthSeriesLite,
    hasPlatinumProgram,
    wsLiteCompletedOnboarding,
    loading,
    hasOptionsAcademy,
    hasEmerald,
  } = useAuth();
  const toggleSidebar = () => {
    if (!isMobile) {
      setCollapsed(!collapsed);
    }
  };
  // Check if user has any wealth series products
  const hasAnyWealthSeriesProduct =
    hasWealthSeriesAtlas ||
    hasWealthSeriesTitan ||
    hasWealthSeriesEverest ||
    hasWealthSeriesLite;

  // Determine if Wealth Series should be the first menu item
  const shouldWealthSeriesBeFirst =
    (!loading && hasAnyWealthSeriesProduct) ||
    (!canSeeInternal &&
      !hasAnyWealthSeriesProduct &&
      !hasPlatinumProgram &&
      !hasOptionsAcademy &&
      !hasEmerald);

  useEffect(() => {
    if (userData) {
      // set default settings for sidebar, send flag to not send network req
      if (!!userData.userSetting && !userData?.notifications) {
        // if user data already exists but notifications have not yet been intialized
        setUserSettings(
          {
            ...userData.userSetting,
            notifications: {
              sound: false,
              mode: 0,
            },
          },
          true,
        );
      } else {
        setUserSettings(userData?.userSettings || userSettings, true);
      }
    }
  }, [userData]);
  const [activeItem, setActiveItem] = useState(null);
  const [highlightedItem, setHighlightedItem] = useState(null);
  const [activeSubItemPath, setActiveSubItemPath] = useState(null);

  // Reference for the submenu container
  const submenuContainer = useRef(null);
  const sidebarRef = useRef(null);
  const submenuRef = useRef(null);
  const [showChat, setShowChat] = useState(1);
  const closeSubmenuTimeoutRef = useRef(null); // Add a ref for the timeout

  // Determine active menu item based on path
  const determineActiveItem = () => {
    // Check for exact match first
    const exactMatch = menuItems.find((item) => item.path === pathName);
    if (exactMatch) {
      return exactMatch.id;
    }

    // Check for partial match in menu items
    const partialMatch = menuItems.find(
      (item) =>
        item.path && pathName.startsWith(item.path) && item.path !== "/",
    );
    if (partialMatch) {
      return partialMatch.id;
    }

    // Special handling for options academy routes
    if (pathName.startsWith("/options-academy")) {
      const optionsTradingItem = menuItems.find(
        (item) => item.id === "options-trading",
      );
      if (optionsTradingItem) {
        return optionsTradingItem.id;
      }
    }

    // Check for submenu matches
    for (const item of menuItems) {
      if (item.hasSubmenu) {
        const items =
          item.submenuType === "simple" ? item.items : item.products;
        if (items) {
          const submenuMatch = items.find(
            (subItem) =>
              subItem.path &&
              pathName.startsWith(subItem.path) &&
              subItem.path !== "/",
          );
          if (submenuMatch) {
            return item.id;
          }
        }
      }
    }
    return null;
  };
  useEffect(() => {
    const url = "https://salesiq.zoho.com/widget";
    const widgetCode =
      "efa68e65bf844744eb262640a827e2d41325819c78d7bd69df353ef0a840a3a928a7206035e855ece77f3c160cbede31";
    const script = document.createElement("script");
    script.setAttribute("type", "text/javascript");
    script.setAttribute("id", "zoho-script");
    let code = `var $zoho=$zoho || {};$zoho.salesiq = $zoho.salesiq || {widgetcode: "${widgetCode}", values:{},ready:function(){}};var d=document;s=d.createElement("script");s.type="text/javascript";s.id="zsiqscript";s.defer=true;s.src="${url}";t=d.getElementsByTagName("script")[0];t.parentNode.insertBefore(s,t);d.innerHTML = "<div id='zsiqwidget'></div>";`;
    script.appendChild(document.createTextNode(code));
    document.body.appendChild(script);
    return () => {
      document.getElementById("zoho-script").remove();
      document.getElementById("zsiqscript").remove();
    };
  }, []);
  useEffect(() => {
    if (showChat > 1) {
      let el = document.getElementById("zsiq_agtpic");
      if (el) el.click();
      setTimeout(() => {
        $("iframe").each(function () {
          $(this)
            .contents()
            .find(".win_close")
            .click(() => {
              //dismissing modal, show chat
              setHideChat(false, liveChatExpanded);
            });
        });
      }, 1000);
    }
  }, [showChat]);

  const ShowChat = () => {
    setShowChat((prev) => prev + 1);
    setHideChat(true, liveChatExpanded);
  };
  // Set highlighted item when route changes
  useEffect(() => {
    const currentHighlightedId = determineActiveItem();
    setHighlightedItem(currentHighlightedId);

    // Also determine the active sub-item path
    let subItemPath = null;
    if (currentHighlightedId) {
      const parentItem = menuItems.find(
        (item) => item.id === currentHighlightedId,
      );
      if (parentItem && parentItem.hasSubmenu) {
        const itemsToCheck =
          parentItem.submenuType === "simple"
            ? parentItem.items
            : parentItem.products;
        const matchedSubItem = itemsToCheck?.find(
          (sub) =>
            sub.path && pathName.startsWith(sub.path) && sub.path !== "/",
        );
        if (matchedSubItem) {
          subItemPath = matchedSubItem.path;
        }
      }
    }
    setActiveSubItemPath(subItemPath);
  }, [pathName]); // Added pathName dependency for sub-item check

  useEffect(() => {
    // Find submenu container
    submenuContainer.current = document.getElementById("submenu-container");
  }, []);

  // Function to get the correct image based on theme mode
  const getThemeImage = (lightImage, darkImage) => {
    return themeMode === "dark" ? darkImage : lightImage;
  };

  const menuItems = [
    {
      id: "dashboard",
      icon: <DashboardIcon isDark={themeMode === "dark"} />,
      label: "Dashboard",
      hasSubmenu: false,
      path: "/home/Intraday/Auto/Up",
      internal: true,
    },
    {
      id: "live-sessions",
      icon: <LiveSessionsIcon isDark={themeMode === "dark"} />,
      label: "Live Sessions",
      hasSubmenu: true,
      submenuType: "simple",
      items: [
        { id: "live-training", label: "Live Training", path: "/live-training" },
        {
          id: "live-trading-room",
          label: "Live Trading Room",
          path: "/market-analysis",
        },
        {
          id: "daytrade-with-brian",
          label: "DayTrade with Brian",
          path: "/live-chat/real-time-trading-alerts",
        },
      ],
      internal: true,
    },
    {
      id: "trading-tools",
      icon: <TradingToolsIcon isDark={themeMode === "dark"} />,
      label: "Trading Tools",
      hasSubmenu: true,
      submenuType: "simple",
      items: [
        { id: "chart-grid", label: "Chart Grid", path: "/chart-grid" },
        {
          id: "historical-darkflow",
          label: "Historical DarkFlow",
          path: "/historical/Auto/Up",
        },
        {
          id: "live-options",
          label: "Live Options",
          path: `/live-options/${minPremium}/${expWithinMin},${expWithinMax}/${minContracts || 0}/${defaultTickersSettings || ""}`,
        },
        { id: "movement", label: "Movement", path: "/movement" },
        {
          id: "option-chain",
          label: "Option Chain",
          path: `/option-chain/${
            userSettings?.defaultOptionChainTicker || "AAPL"
          }`,
        },
        { id: "scanner", label: "Scanner", path: "/scanner" },
        {
          id: "swing-trades",
          label: "Swing Trades AI",
          path: "/swing-trades/0",
        },
        { id: "trade-alerts", label: "Trade Alerts", path: "/alerts/Auto/Up" },
        { id: "tutorials", label: "Tutorials", path: "/tutorials" },
        {
          id: "live-walkthrough",
          label: "Live Walkthrough",
          path: "/live-walkthrough",
        },
      ],
      internal: true,
    },
    {
      id: "wealth-series",
      icon: <WealthSeriesIcon isDark={themeMode === "dark"} />,
      label: "Wealth Series",
      submenuLabel: "PREMIUM",
      hasSubmenu: true,
      path: "/wealth-series-signup",
      products: [
        {
          id: "everest",
          name: "Everest",
          description:
            "An exclusive solution for unparalleled wealth management.",
          image: getThemeImage(everest, everestDark),
          path: hasWealthSeriesEverest
            ? "/wealth-series/everest"
            : "/wealth-series-signup",
          hasAccess: hasWealthSeriesEverest,
        },
        {
          id: "titan",
          name: "Titan",
          description:
            "Optimized portfolios designed to protect & amplify your wealth.",
          image: getThemeImage(titan, titanDark),
          path: hasWealthSeriesTitan
            ? "/wealth-series/titan"
            : "/wealth-series-signup",
          hasAccess: hasWealthSeriesTitan,
        },
        {
          id: "atlas",
          name: "Atlas",
          description: "Powerful portfolios for steady and scalable success.",
          image: getThemeImage(atlas, atlasDark),
          path: hasWealthSeriesAtlas
            ? "/wealth-series/atlas"
            : "/wealth-series-signup",
          hasAccess: hasWealthSeriesAtlas,
        },
        {
          id: "lite",
          name: "Lite",
          description:
            "AI stock recommendations designed to fit every investor's strategy.",
          image: getThemeImage(lite, liteDark),
          path: hasWealthSeriesLite
            ? wsLiteCompletedOnboarding
              ? `/wealth-series-lite/ai-top-picks`
              : `/wealth-series-lite-onboarding`
            : `/wealth-series-lite-signup`,
          hasAccess: hasWealthSeriesLite,
        },
      ],
      isNew: true,
      internal: false,
    },
    {
      id: "options-trading",
      icon: <OptionsIcon isDark={themeMode === "dark"} />,
      label: "Options Trading",
      submenuLabel: "OPTIONS",
      hasSubmenu: true,
      products: [
        {
          id: "options-academy",
          name: "Options Academy",
          description: "Demystify the complexities of trading options",
          image: getThemeImage(optionsAcademy, optionsAcademy),
          path: optionsAcademyPath,
          hasAccess:
            optionsAcademyPath && !optionsAcademyPath.includes("-signup"),
        },
        {
          id: "platinum",
          name: "Platinum",
          description:
            "Master advanced options and track trades to profit using technical analysis",
          image: getThemeImage(platinum, platinumDark),
          path: platinumProgramPath,
          hasAccess: hasPlatinumProgram,
        },
        {
          id: "emerald",
          name: "Emerald",
          description:
            "Elevate your trading strategy and boost your profit with options support",
          image: getThemeImage(emerald, emeraldDark),
          path: emeraldPath,
          hasAccess: emeraldPath && !emeraldPath.includes("-signup"),
        },
        {
          id: "options-mentorship",
          name: "Options Mentorship",
          description:
            "Get 1-on-1 Mentorship with our analysts to take your options portfolio to new heights",
          image: getThemeImage(optionsMentorship, optionsMentorshipDark),
          path: optionsMentorshipPath,
          hasAccess: optionsMentorshipPath.includes("overview"),
        },
      ],
      internal: false,
    },
    {
      id: "tradealgo-products",
      icon: <ProductsIcon isDark={themeMode === "dark"} />,
      label: "TradeAlgo Products",
      hasSubmenu: false,
      path: "/products",
    },
    {
      id: "support",
      icon: <SupportIcon isDark={themeMode === "dark"} />,
      label: "Support",
      hasSubmenu: true,
      submenuType: "simple",
      items: [
        {
          id: "release-notes",
          label: "Release Notes",
          path: "/release-notes",
          isNew: true,
        },
        {
          id: "email-support",
          label: "Email Support",
          mailTo: "mailto:support@tradealgo.com",
        },
        {
          id: "chat-support",
          label: "Chat Support",
          onClick: () => {
            // Add chat support functionality
            ShowChat();
          },
        },
      ],
      internal: false,
    },
    {
      id: "settings",
      icon: <SettingsIcon isDark={themeMode === "dark"} />,
      label: "Settings",
      hasSubmenu: false,
      path: "/settings/profile",
      pathPattern: "/settings/",
      internal: false,
    },
  ];

  const handleMouseEnter = (id) => {
    if (!isMobile) {
      // Clear any pending timeout to close the submenu
      if (closeSubmenuTimeoutRef.current) {
        clearTimeout(closeSubmenuTimeoutRef.current);
        closeSubmenuTimeoutRef.current = null;
      }
      setActiveItem(id);
    }
  };

  const handleMouseLeave = (e) => {
    if (isMobile) return;
    // Immediately check if we have a valid event
    if (!e || typeof e !== "object") {
      return;
    }

    // Clear any existing timeout before starting a new one
    if (closeSubmenuTimeoutRef.current) {
      clearTimeout(closeSubmenuTimeoutRef.current);
    }

    // Start a short delay before closing the submenu
    closeSubmenuTimeoutRef.current = setTimeout(() => {
      try {
        setActiveItem(null);
      } catch (error) {
        // Silently handle any errors
      }
      closeSubmenuTimeoutRef.current = null; // Clear ref after execution
    }, 200);
  };

  // Create a single portal for all submenus
  const renderSubmenu = () => {
    if (isMobile) return null; // Don't render hover submenus on mobile

    if (!submenuContainer.current) {
      return null;
    }

    // Find the active menu item
    const activeMenu = menuItems.find((item) => item.id === activeItem);

    // Only render if there's an active item with a submenu
    if (!activeMenu || !activeMenu.hasSubmenu) {
      return null;
    }

    return ReactDOM.createPortal(
      <div
        ref={submenuRef}
        className={`fullheight-submenu ${activeItem ? "active" : ""}`}
        onMouseEnter={() => {
          // Clear the timeout if mouse enters the submenu
          if (closeSubmenuTimeoutRef.current) {
            clearTimeout(closeSubmenuTimeoutRef.current);
            closeSubmenuTimeoutRef.current = null;
          }
        }}
        onMouseLeave={(e) => {
          // Immediately check if we have a valid event
          if (!e || typeof e !== "object") {
            return;
          }

          // Clear any existing timeout before starting a new one
          if (closeSubmenuTimeoutRef.current) {
            clearTimeout(closeSubmenuTimeoutRef.current);
          }

          // Start a short delay before closing the submenu when leaving the submenu itself
          closeSubmenuTimeoutRef.current = setTimeout(() => {
            try {
              // Check if moving back to sidebar - THIS CHECK MIGHT BE PROBLEMATIC with async timeout
              // Need a reliable way to know where the mouse went *after* the timeout.
              // A simpler approach: If the timeout finishes, just close.
              // The onMouseEnter on the sidebar items will cancel this timeout if needed.

              setActiveItem(null); // Close submenu if timeout completes
            } catch (error) {
              // Silently handle any errors
            }
            closeSubmenuTimeoutRef.current = null; // Clear ref after execution
          }, 150); // Adjust delay as needed
        }}
      >
        <div className="submenu-content">
          {activeMenu.submenuType === "simple" ? (
            <SimpleSubmenu
              items={activeMenu.items}
              onItemClick={handleSubmenuItemClick}
              activeSubItemPath={activeSubItemPath}
            />
          ) : (
            <>
              <h3 className="submenu-title">{activeMenu?.submenuLabel}</h3>
              <div className="submenu-items">
                {activeMenu?.products?.map((product) => (
                  <SubmenuProductCard
                    key={product.id}
                    product={product}
                    onClick={() => handleSubmenuItemClick(product)}
                    activeSubItemPath={activeSubItemPath}
                  />
                ))}
              </div>
            </>
          )}
        </div>
      </div>,
      submenuContainer.current,
    );
  };

  // Render mobile submenu inside the sidebar
  const renderMobileSubmenu = () => {
    if (!isMobile || !activeSubmenu) return null;

    // Find the active menu item
    const activeMenu = menuItems.find((item) => item.id === activeSubmenu);

    // Only render if there's an active submenu
    if (!activeMenu || !activeMenu.hasSubmenu) {
      return null;
    }

    return (
      <div className="mobile-submenu">
        <IconButton
          onClick={closeMobileMenu}
          className="mobile-collapse-btn pl-5"
          aria-label="Close menu"
        >
          <CollapseIcon
            className="ml-8"
            isDark={themeMode === "dark"}
            size={30}
          />
        </IconButton>
        <div className="mobile-submenu-header">
          <button className="back-button " onClick={closeSubmenu}>
            <ArrowBackIcon />
            <span>Back to Menu</span>
          </button>
          {activeMenu.submenuLabel && (
            <h3 className="submenu-title">{activeMenu.submenuLabel}</h3>
          )}
        </div>
        <div className="mobile-submenu-content">
          {activeMenu.submenuType === "simple" ? (
            <SimpleSubmenu
              items={activeMenu.items}
              isMobile={true}
              onItemClick={handleSubmenuItemClick}
              activeSubItemPath={activeSubItemPath}
            />
          ) : (
            <div className="submenu-items">
              {activeMenu?.products?.map((product) => (
                <SubmenuProductCard
                  key={product.id}
                  product={product}
                  onClick={() => handleSubmenuItemClick(product)}
                  activeSubItemPath={activeSubItemPath}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  // Conditional classes for mobile
  const sidebarClass = isMobile
    ? `sidebar mobile ${mobileMenuOpen ? "mobile-open" : "mobile-closed"}`
    : `sidebar ${collapsed ? "collapsed" : ""}`;

  // Function to handle menu item click with navigation
  const handleMenuItemClick = (item) => {
    if (item.path) {
      // Only navigate if it's a different path
      if (pathName !== item.path) {
        history.push(item.path);
      }
    }

    if (isMobile) {
      if (item.hasSubmenu) {
        // Open submenu in mobile view
        closeSubmenu();
        setTimeout(() => {
          openSubmenu(item.id);
        }, 10);
      } else {
        // If it's a regular menu item (no submenu), close the mobile sidebar
        closeMobileMenu();
      }
    }
  };

  // Function to handle submenu item click
  const handleSubmenuItemClick = (item) => {
    if (item.mailTo) {
      // Handle mailto links
      window.location.href = item.mailTo;
    } else if (item.onClick) {
      // Execute custom onClick function
      item.onClick();
    } else if (item.path) {
      // Handle normal navigation
      history.push(item.path);
    }

    if (isMobile) {
      closeMobileMenu();
    } else {
      setActiveItem(null); // Close desktop submenu
    }
  };

  // Determine if a menu item is active based on the current path or mobile state
  const isItemActive = (item) => {
    // Active state determined by current route path OR if the mobile submenu is open for this item
    return (
      highlightedItem === item.id || (isMobile && activeSubmenu === item.id)
    );
  };

  // Centralized function to render a single menu item with disabled logic
  const renderSingleMenuItem = (item) => {
    const isDisabled = item.internal && !canSeeInternal;
    const active = isItemActive(item);

    return (
      <div
        key={item.id}
        className={`menu-item ${active ? "active" : ""} ${isDisabled ? "disabled" : ""}`}
        // Conditionally attach handlers only if not disabled
        onMouseEnter={!isDisabled ? () => handleMouseEnter(item.id) : undefined}
        onMouseLeave={!isDisabled ? handleMouseLeave : undefined} // Add conditional onMouseLeave too
        onClick={!isDisabled ? () => handleMenuItemClick(item) : undefined}
      >
        {/* Reuse existing renderMenuItem logic for content */}
        <div className="menu-item-content">
          <span className="icon">
            {React.cloneElement(item.icon, { active: active && !isDisabled })}{" "}
            {/* Pass active only if not disabled */}
          </span>
          {(!collapsed || isMobile) && (
            <>
              <span className="label">{item.label}</span>
              {item.isNew && <span className="new-badge">New</span>}
              {item.hasSubmenu && (
                <div className="submenu-arrow-wrapper">
                  <ChevronRightIcon className="submenu-arrow" size={16} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <div ref={sidebarRef} className={sidebarClass}>
      {isMobile && !activeSubmenu && (
        <div className="mobile-sidebar-header">
          <IconButton
            onClick={closeMobileMenu}
            className="mobile-collapse-btn"
            aria-label="Close menu"
          >
            <CollapseIcon isDark={themeMode === "dark"} size={30} />
          </IconButton>
        </div>
      )}

      {!isMobile && (
        <div className="sidebar-header">
          <div
            onClick={() => {
              if (collapsed) {
                toggleSidebar();
              } else {
                history.push(
                  canSeeInternal ? "/home/Intraday/Auto/Up" : "/products",
                );
              }
            }}
            className="logo cursor-pointer"
          >
            <img
              className="ta-logo-sidebar"
              src={themeMode === "light" ? TALogoDark : TALogoLight}
              alt="TradeAlgo Logo"
            />
            {!collapsed && <span className="logo-text">TradeAlgo</span>}
          </div>
          {!collapsed && (
            <IconButton className="collapse-btn" onClick={toggleSidebar}>
              <CollapseIcon isDark={themeMode === "dark"} size={30} />
            </IconButton>
          )}
        </div>
      )}

      {isMobile && activeSubmenu ? (
        renderMobileSubmenu()
      ) : (
        <div className="sidebar-menu">
          <div className="menu-section top">
            {shouldWealthSeriesBeFirst ? (
              // Wealth Series first when condition is met
              <>
                {renderSingleMenuItem(menuItems[3])}
                {menuItems
                  .slice(0, 3)
                  .map((item) => renderSingleMenuItem(item))}
              </>
            ) : (
              // Normal order
              menuItems.slice(0, 3).map((item) => renderSingleMenuItem(item))
            )}
          </div>

          <div className="divider" />

          <div className="menu-section middle">
            {shouldWealthSeriesBeFirst
              ? // Skip Wealth Series as it's already shown above
                null
              : // Normal rendering of Wealth Series
                menuItems.slice(3, 4).map((item) => renderSingleMenuItem(item))}
          </div>

          <div className="divider" />

          <div className="menu-section middle-2">
            {menuItems.slice(4, 5).map((item) =>
              // Use the centralized rendering function
              renderSingleMenuItem(item),
            )}
          </div>

          <div className="menu-section bottom">
            {/* Explicitly render the single item using the function */}
            {renderSingleMenuItem(menuItems[5])}

            <div className="divider" />

            {menuItems.slice(6).map((item) =>
              // Use the centralized rendering function
              renderSingleMenuItem(item),
            )}
          </div>
        </div>
      )}

      {renderSubmenu()}

      {!isMobile && (
        <div className="sidebar-footer">
          <ThemeToggle collapsed={collapsed} />
        </div>
      )}

      {isMobile && !activeSubmenu && (
        <div className="sidebar-footer mobile">
          <ThemeToggle />
        </div>
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
  defaultTickersSettings:
    state.userSettings.userSettings.defaultLiveOptionsTickers,
  liveChatExpanded: state.hideLiveChat.liveChatExpanded,
});

const dispatchToProps = (dispatch) => ({
  setHideChat: (open, liveChatExpanded) =>
    dispatch(liveChatSetter(open, liveChatExpanded)),
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(Sidebar);
