import React from "react";

const OptionsIcon = ({
  color = "rgba(0, 0, 0, 0.56)",
  size = 24,
  active = false,
  isDark = false,
}) => {
  // Handle color logic based on state
  const iconColor = active
    ? "#007bff" // Active color
    : isDark
      ? "rgba(255, 255, 255, 0.8)" // Dark theme
      : color; // Default color

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 6C15 6.55 15.45 7 16 7H20C20.55 7 21 6.55 21 6C21 5.45 20.55 5 20 5H16C15.45 5 15 5.45 15 6Z"
        fill={iconColor}
      />
      <path
        d="M9.58 6C9.22 5.38 8.56 5 7.85 5H4C3.45 5 3 5.45 3 6C3 6.55 3.45 7 4 7H7.85L14.2 18C14.56 18.62 15.22 19 15.93 19H20C20.55 19 21 18.55 21 18C21 17.45 20.55 17 20 17H15.93L9.58 6Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default OptionsIcon;
