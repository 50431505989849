import "./SubmenuProductCard.scss";

const SubmenuProductCard = ({ product, onClick, activeSubItemPath }) => {
  const { name, description, image, path, hasAccess } = product;
  const isActive = activeSubItemPath === path;

  // Use explicit hasAccess property if available,
  // otherwise infer from path (for backwards compatibility)
  const isSubscribed =
    hasAccess !== undefined
      ? hasAccess
      : path && !path.includes("-signup") && !path.startsWith("http");

  return (
    <div
      className={`submenu-product-card ${isActive ? "active" : ""}`}
      onClick={onClick}
    >
      <div className="product-logo">
        <img src={image} alt={name} className="product-image" />
      </div>
      <div className="product-info">
        <div className="product-name-row">
          <div className="product-name">{name}</div>
          {isSubscribed && <div className="subscribed-badge">Subscribed</div>}
        </div>
        <div className="product-description">{description}</div>
      </div>
    </div>
  );
};

export default SubmenuProductCard;
