import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import { routerMiddleware } from "connected-react-router";
import createRootReducer from "./appRedux/reducers/rootReducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "./appRedux/sagas/index";
import thunk from "redux-thunk";
export const history = createBrowserHistory();

const routeMiddleware = routerMiddleware(history);
const sagaMiddleware = createSagaMiddleware();

const middlewares = [thunk, sagaMiddleware, routeMiddleware];

// Create a store instance that can be exported
let store;

export default function configureStore(initialState = {}) {
  const reducer = createRootReducer(history);

  // Should we use redux debugger or not?
  const composeEnhancers =
    process.env["REACT_APP_RELEASE_STAGE"] !== "production" &&
    window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const enhancer = composeEnhancers(applyMiddleware(...middlewares));
  store = createStore(
    reducer, // root reducer with router state
    initialState,
    enhancer,
  );

  sagaMiddleware.run(rootSaga);

  return store;
}

// Export the store instance
export { store };
