import React, { useEffect, useMemo, useState } from "react";
import { ListItemText, makeStyles, Tooltip } from "@material-ui/core";
import { abbreviate } from "../../homePage/TradeAlgoCard";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { connect } from "react-redux";
import { addUserSettingsLoad } from "../../../appRedux/ducks/userSettings";
import clsx from "clsx";
import DoneIcon from "@mui/icons-material/Done";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuItem from "@mui/material/MenuItem";
import NestedMenuItem from "material-ui-nested-menu-item";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import "./LiveOptionsSettingsPage.scss";
import { useAuth } from "../../../contexts/Auth";
import OptionTickerSearch from "../../OptionScanner/OptionTickerSearch";
import useDebounce from "../../OptionScanner/useDebounce";
import { searchLoad } from "../../../appRedux/ducks/search";
import BeautifulSkeleton from "../../common/Skeletons/BeautifulSkeleton";
import { ConditionalWrapper } from "../../common/Layout/PrivateLayout/Sidebar";

const minPrems = [
  35000, 50000, 100000, 150000, 300000, 500000, 1e6, 2e6, 5e6, 10e6,
];

const minCons = Array.from({ length: 101 }).map((i, index) => index * 1000);
const expiresWithin = [0, 1, 7, 14, 28, 56, 112, 224, 448, 730];

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundColor: theme.palette.primary.tableBackground,
  },
  text: {
    color: theme.palette.primary.text + " !important",
    textDecoration: "none !important",

    "& span": {
      fontSize: "14px!important",
    },
  },
  fill: {
    fill: theme.palette.primary.text + " !important",
  },
}));

function onMouseEnter() {
  if (
    !document
      ?.getElementsByClassName("html-el")[0]
      ?.classList?.contains("open-popover")
  ) {
    document
      ?.getElementsByClassName("html-el")[0]
      ?.classList?.add("open-popover");
  }
}
function onMouseLeave() {
  if (
    document
      ?.getElementsByClassName("html-el")[0]
      ?.classList?.contains("open-popover")
  ) {
    document
      ?.getElementsByClassName("html-el")[0]
      ?.classList?.remove("open-popover");
  }
}

const LiveOptionsSettingsPage = ({
  firstRender,
  searchAllSymbols,
  symbolsLoading,
  searchedSymbols,
  themeMode,
  userSettings,
  setUserSettings,
}) => {
  const { loading } = useAuth();
  const {
    minPremium,
    minContracts = 0,
    expWithinMin = 1,
    expWithinMax = 730,
    defaultLiveOptionsTickers: defaultTickersSettings = "",
  } = userSettings;
  const [open, setOpen] = useState(false);
  const [ticker, setTicker] = useState([]);
  const [symbol, setSymbol] = useState("");
  const [tickerString, setTickerString] = useState("");
  const debouncedSymbol = useDebounce(symbol, 500);

  useEffect(() => {
    if (firstRender) {
      const tickers = defaultTickersSettings.length
        ? defaultTickersSettings?.split(",")?.map((ticker) => ({ ticker }))
        : [];
      setTicker(tickers);
    }
  }, [firstRender]);

  useEffect(() => {
    if (debouncedSymbol.length) {
      searchAllSymbols(debouncedSymbol);
    }
  }, [debouncedSymbol]);
  const setMinPremium = (minPremium) => {
    setUserSettings({
      ...userSettings,
      minPremium,
    });
  };
  const setMinContracts = (minContracts) => {
    setUserSettings({
      ...userSettings,
      minContracts,
    });
  };
  const setExpWithinMin = (expWithinMin) => {
    setUserSettings({
      ...userSettings,
      expWithinMin,
    });
  };

  const setExpWithinMax = (expWithinMax) => {
    setUserSettings({
      ...userSettings,
      expWithinMax,
    });
  };
  const setDefaultTickers = (defaultLiveOptionsTickers) => {
    if (defaultLiveOptionsTickers !== defaultTickersSettings) {
      setUserSettings({
        ...userSettings,
        defaultLiveOptionsTickers,
      });
    }
  };

  useEffect(() => {
    if (tickerString.length) {
      setDefaultTickers(tickerString);
    }
  }, [tickerString]);

  const classes = useStyles();
  const color = useMemo(() => {
    return themeMode === "dark" ? "white" : "black";
  }, [themeMode]);

  const menuData = useMemo(() => {
    const minPremiumSubnav = minPrems.map((prem) => ({
      title: abbreviate(prem),
      onClick: () => setMinPremium(prem),
      icon: (
        <AttachMoneyIcon
          style={{
            color,
          }}
        />
      ),
      checked: Number(minPremium) == Number(prem),
    }));
    const minContractsSubnav = minCons.map((num) => ({
      title: abbreviate(num),
      onClick: () => setMinContracts(num),
      icon: (
        <RequestPageIcon
          style={{
            color,
          }}
        />
      ),
      checked: Number(minContracts) == Number(num),
    }));

    const expiresWithinMinSubnav = expiresWithin.map((exp) => ({
      title: exp + " Days",
      className: "reyu8",
      onClick: () => setExpWithinMin(exp),
      icon: (
        <CalendarMonthIcon
          style={{
            color,
          }}
        />
      ),
      checked: Number(expWithinMin) == Number(exp),
    }));
    const expiresWithinMaxSubnav = expiresWithin.map((exp) => ({
      title: exp + " Days",
      className: "reyu8",
      onClick: () => setExpWithinMax(exp),
      icon: (
        <CalendarMonthIcon
          style={{
            color,
          }}
        />
      ),
      checked: Number(expWithinMax) == Number(exp),
    }));
    const MinConTitle = minContractsSubnav.find((item) => item?.checked)?.title;
    const checkedMinimumPremiumTitle = minPremiumSubnav.find(
      (item) => item?.checked,
    )?.title;
    const checkedExpiresWithinTitle = expiresWithinMinSubnav.find(
      (item) => item?.checked,
    )?.title;
    const checkedExpiresWithinMaxTitle = expiresWithinMaxSubnav.find(
      (item) => item?.checked,
    )?.title;

    const items = {
      MinContracts: {
        title: MinConTitle,
        className: "settings-page-menu-item",
        icon: (
          <RequestPageIcon
            style={{
              color,
            }}
          />
        ),
        subNav: minContractsSubnav,
      },
      MinimumPremium: {
        title: checkedMinimumPremiumTitle,
        className: "settings-page-menu-item",
        icon: (
          <AttachMoneyIcon
            style={{
              color,
            }}
          />
        ),
        subNav: minPremiumSubnav,
      },

      ExpiresWithinMin: {
        title: checkedExpiresWithinTitle,
        className: "settings-page-menu-item",
        icon: (
          <CalendarMonthIcon
            style={{
              color,
            }}
          />
        ),
        subNav: expiresWithinMinSubnav,
      },
      ExpiresWithinMax: {
        title: checkedExpiresWithinMaxTitle,
        className: "settings-page-menu-item",
        icon: (
          <CalendarMonthIcon
            style={{
              color,
            }}
          />
        ),
        subNav: expiresWithinMaxSubnav,
      },
    };

    return items;
  }, [color, minPremium, expWithinMin, expWithinMax, minContracts]);

  const renderMenuItems = (item) => {
    return (
      <React.Fragment>
        <NestedMenuItem
          className={item?.className || ""}
          label={
            <React.Fragment>
              {item.icon}
              <ListItemText
                style={{ paddingLeft: "6px" }}
                className={clsx(
                  classes.text,
                  "list-item-text",
                  item?.customClass || "",
                )}
              >
                {item.title}
              </ListItemText>
            </React.Fragment>
          }
          parentMenuOpen={true}
          rightIcon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
            >
              <path
                d="M5.1714 7.00328L0.22168 2.05348L1.63589 0.639278L7.9999 7.00328L1.63589 13.3672L0.22168 11.953L5.1714 7.00328Z"
                fill="#CCCCCC"
              />
            </svg>
          }
        >
          {item.subNav.map((subItem, subIndex) => {
            return (
              <React.Fragment key={subIndex}>
                <ConditionalWrapper
                  condition={!!subItem?.tooltip}
                  wrapper={(children) => (
                    <Tooltip placement="top" title={subItem?.tooltip}>
                      {children}
                    </Tooltip>
                  )}
                >
                  <MenuItem
                    className="live-options-settings-page-custom-menu-item"
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={subItem.onClick}
                  >
                    <ListItemIcon>{subItem.icon}</ListItemIcon>
                    <ListItemText
                      className={clsx(
                        classes.text,
                        "live-options-settings-page-custom-list-item-text",
                      )}
                    >
                      {subItem.title}
                    </ListItemText>
                    {subItem.checked && (
                      <ListItemIcon className="settings-page-done-checked-icon">
                        <DoneIcon style={{ color }} />
                      </ListItemIcon>
                    )}
                  </MenuItem>
                </ConditionalWrapper>
              </React.Fragment>
            );
          })}
        </NestedMenuItem>
      </React.Fragment>
    );
  };

  return (
    <div className="live-options-settings-page">
      <div className="live-options-settings-page__header">
        Live Options Settings
      </div>
      <div className="live-options-settings-page__subheader">
        Changes here will be reflected as your default settings for all
        contracts in the Live Options Feed.
      </div>
      <div className="live-options-settings-page__content">
        <div className="live-options-settings-page__menu__item">
          <div className="live-options-settings-page__menu__item__title">
            Minimum Premium
          </div>
          <div className="live-options-settings-page__menu__item__data">
            {loading ? (
              <BeautifulSkeleton
                variant="rectangular"
                width={"100%"}
                height={43}
              />
            ) : (
              renderMenuItems(menuData["MinimumPremium"])
            )}
          </div>
        </div>

        <div className="live-options-settings-page__menu__item">
          <div className="live-options-settings-page__menu__item__title">
            Minimum Contracts
          </div>
          <div className="live-options-settings-page__menu__item__data">
            {loading ? (
              <BeautifulSkeleton
                variant="rectangular"
                width={"100%"}
                height={43}
              />
            ) : (
              renderMenuItems(menuData["MinContracts"])
            )}
          </div>
        </div>

        <div className="live-options-settings-page__menu__item">
          <div className="live-options-settings-page__menu__item__title">
            Min Expire Within
          </div>
          <div className="live-options-settings-page__menu__item__data">
            {loading ? (
              <BeautifulSkeleton
                variant="rectangular"
                width={"100%"}
                height={43}
              />
            ) : (
              renderMenuItems(menuData["ExpiresWithinMin"])
            )}
          </div>
        </div>
        <div className="live-options-settings-page__menu__item">
          <div className="live-options-settings-page__menu__item__title">
            Max Expire Within
          </div>
          <div className="live-options-settings-page__menu__item__data">
            {loading ? (
              <BeautifulSkeleton
                variant="rectangular"
                width={"100%"}
                height={43}
              />
            ) : (
              renderMenuItems(menuData["ExpiresWithinMax"])
            )}
          </div>
        </div>

        <div className="live-options-settings-page__menu__item search-box">
          <div className="live-options-settings-page__menu__item__title">
            Default Tickers
          </div>
          <div className="live-options-settings-page__menu__item__data ">
            {loading ? (
              <BeautifulSkeleton
                variant="rectangular"
                width={"250px"}
                height={43}
              />
            ) : (
              <OptionTickerSearch
                open={open}
                setOpen={setOpen}
                valueLabelDisplay="on"
                options={searchedSymbols}
                value={symbol}
                setSymbol={setSymbol}
                symbol={symbol}
                tickers={ticker}
                loading={symbolsLoading}
                setTicker={setTicker}
                setTickerString={setTickerString}
                customMinWidth={250}
                setDefaultTickers={setDefaultTickers}
                fromSettingsPage
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
  searchedSymbols: state.searchSymbols.searchSymbols,
  symbolsLoading: state.searchSymbols.symbolsLoading,
  firstRender: state.userSettings.firstRender,
});

const dispatchToProps = (dispatch) => ({
  searchAllSymbols: (symbol, exclude = false) =>
    dispatch(searchLoad(symbol, exclude)),
  setUserSettings: (settings, firstRender) =>
    dispatch(addUserSettingsLoad(settings, firstRender)),
});

export default connect(stateToProps, dispatchToProps)(LiveOptionsSettingsPage);
