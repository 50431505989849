import React from "react";

const WealthSeriesIcon = ({
  color = "#E4B862", // Default gold color
  size = 24,
  active = false,
  isDark = false,
}) => {
  // For wealth series, we maintain the gold color regardless of theme
  // but we can brighten it when active
  const iconColor = active
    ? "#FFD700" // Brighter gold when active
    : color; // Default gold color

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.358 6C15.3004 8.37101 17.1768 9.5821 19.5648 10.9297C22.0723 12.3454 23.9998 13.096 23.9998 15.2111C23.9998 16.9169 22.6864 17.9403 21.0659 17.9403C22.4987 15.7058 21.4753 14.7506 17.6885 12.6354C15.1981 11.2537 14.0723 10.1621 14.0723 8.33689C14.0723 7.0064 14.9422 6 16.358 6ZM20.5883 6H23.1469V8.55864C22.6352 7.02346 22.1235 6.51173 20.5883 6Z"
        fill={iconColor}
      />
      <path
        d="M14.8092 17.8919C15.4205 17.8799 16.8104 17.8918 17.8173 17.8919C16.3193 17.4305 16.3193 16.9811 15.5703 15.3333C15.5703 15.3333 15.7201 17.8868 14.8092 17.8919Z"
        fill={iconColor}
      />
      <path
        d="M5.54373 8.55864L7.43712 12.7548C8.59704 15.3646 7.7271 16.8657 7.14714 17.9403L2.8998 8.55864C2.18338 7.02346 1.31344 6.34115 0 6C3.5821 6 4.72496 6.69936 5.54373 8.55864ZM11.5651 8.55864L13.4585 12.7548C14.6184 15.3646 13.7484 16.8657 13.1685 17.9403L8.92114 8.55864C8.20472 7.02346 7.33478 6.34115 6.02134 6H12.5544C11.4968 6.34115 10.8828 7.02346 11.5651 8.55864Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default WealthSeriesIcon;
