import React, { useState } from "react";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import "./PlaidModal.scss";
import { numberWithCommas } from "../../utilities";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const PlaidInvoiceModal = ({
  productName,
  totalAmount,
  amountToPay,
  isPartialPayment,
  paidAmount = 0, // Default to 0 for backward compatibility
  onClose,
  onAction,
}) => {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };

  const handleAction = () => {
    if (onAction) {
      onAction();
    }
    handleClose();
  };

  // Simple calculation - works for both partial and full payments
  const remainingBalance = totalAmount - amountToPay - paidAmount;

  return (
    <BootstrapDialog
      aria-labelledby="plaid-integration-dialog"
      className="plaid-integration-dialog"
      open={open}
    >
      <DialogTitle
        sx={{ m: 0, p: 3 }}
        className="plaid-integration-modal-dialog-title"
      >
        <div className="plaid-integration-modal-dialog-subtitle">
          {isPartialPayment
            ? `Complete your remaining payment to access ${productName}`
            : `Complete your payment now and gain access to ${productName}`}
        </div>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{ position: "absolute", right: 16, top: 16, padding: 0 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        <div className="plaid-integration-modal-dialog-content">
          <div className="plaid-integration-prices">
            <div className="plaid-integration-price">
              <div className="plaid-integration-price-title">Total</div>
              <div className="plaid-integration-price-value">
                ${numberWithCommas(totalAmount)}
              </div>
            </div>

            {isPartialPayment && paidAmount > 0 && (
              <div className="plaid-integration-price">
                <div className="plaid-integration-price-title">
                  Already Paid
                </div>
                <div className="plaid-integration-price-value">
                  ${numberWithCommas(paidAmount)}
                </div>
              </div>
            )}

            <div className="plaid-integration-price">
              <div className="plaid-integration-price-title">Amount to pay</div>
              <div className="plaid-integration-price-value">
                ${numberWithCommas(amountToPay)}
              </div>
            </div>
          </div>
          <div className="plaid-integration-divider" />
          <div className="plaid-integration-balance">
            <div className="plaid-integration-balance-title">
              Balance due after payment
            </div>
            <div className="plaid-integration-balance-value">
              ${numberWithCommas(remainingBalance)}
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          className="plaid-integration-modal-action-button"
          onClick={onAction ? handleAction : handleClose}
        >
          {onAction ? "Proceed to Payment" : "Close"}
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
};

export default PlaidInvoiceModal;
