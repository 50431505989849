import React from "react";

const ProductsIcon = ({
  color = "rgba(0, 0, 0, 0.56)",
  size = 24,
  active = false,
  isDark = false,
}) => {
  // Handle color logic based on state
  const iconColor = active
    ? "#007bff" // Active color
    : isDark
      ? "rgba(255, 255, 255, 0.8)" // Dark theme
      : color; // Default color

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 11.55C10.18 9.85 7.88 8.66 5.32 8.2C4.11 7.99 3 8.95 3 10.18V16.42C3 18.1 3.72 18.98 4.71 19.11C7.21 19.43 9.48 20.46 11.34 21.98C11.69 22.27 12.26 22.3 12.61 22.02C14.48 20.49 16.77 19.44 19.29 19.12C20.23 18.99 21 18.06 21 17.1V10.18C21 8.95 19.89 7.99 18.68 8.2C16.12 8.66 13.82 9.85 12 11.55ZM12 8C13.66 8 15 6.66 15 5C15 3.34 13.66 2 12 2C10.34 2 9 3.34 9 5C9 6.66 10.34 8 12 8Z"
        fill={iconColor}
      />
    </svg>
  );
};

export default ProductsIcon;
