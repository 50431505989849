import { store } from "../store";
import {
  showSuccessAlert,
  showErrorAlert,
  showInfoAlert,
  showPendingAlert,
  showGlobalAlert,
  hideGlobalAlert,
  clearAllAlerts,
} from "../appRedux/ducks/globalAlerts";

/**
 * Utility functions for showing global alerts
 */

/**
 * Show a success alert
 * @param {string} title - The alert title
 * @param {string} subtitle - The alert subtitle (optional)
 * @param {object} options - Additional options (optional)
 * @returns {string} The alert ID
 */
export const showSuccess = (title, subtitle = "", options = {}) => {
  const action = store.dispatch(showSuccessAlert(title, subtitle, options));
  return action.payload.id;
};

/**
 * Show an error alert
 * @param {string} title - The alert title
 * @param {string} subtitle - The alert subtitle (optional)
 * @param {object} options - Additional options (optional)
 * @returns {string} The alert ID
 */
export const showError = (title, subtitle = "", options = {}) => {
  const action = store.dispatch(showErrorAlert(title, subtitle, options));
  return action.payload.id;
};

/**
 * Show an info alert
 * @param {string} title - The alert title
 * @param {string} subtitle - The alert subtitle (optional)
 * @param {object} options - Additional options (optional)
 * @returns {string} The alert ID
 */
export const showInfo = (title, subtitle = "", options = {}) => {
  const action = store.dispatch(showInfoAlert(title, subtitle, options));
  return action.payload.id;
};

/**
 * Show a pending alert
 * @param {string} title - The alert title
 * @param {string} subtitle - The alert subtitle (optional)
 * @param {object} options - Additional options (optional)
 * @returns {string} The alert ID
 */
export const showPending = (title, subtitle = "", options = {}) => {
  const action = store.dispatch(showPendingAlert(title, subtitle, options));
  return action.payload.id;
};

/**
 * Hide a specific alert by ID
 * @param {string} id - The alert ID to hide
 */
export const hideAlert = (id) => {
  store.dispatch(hideGlobalAlert(id));
};

/**
 * Clear all alerts
 */
export const clearAlerts = () => {
  store.dispatch(clearAllAlerts());
};

/**
 * Show an alert with a button
 * @param {string} title - The alert title
 * @param {string} subtitle - The alert subtitle (optional)
 * @param {string} buttonText - The button text
 * @param {function} onButtonClick - The button click handler
 * @param {string} type - The alert type (primary, success, error)
 * @param {string} iconType - The icon type (info, success, error, pending)
 * @param {object} options - Additional options (optional)
 * @returns {string} The alert ID
 */
export const showAlertWithButton = (
  title,
  subtitle = "",
  buttonText,
  onButtonClick,
  type = "primary",
  iconType = "info",
  options = {},
) => {
  const action = store.dispatch(
    showGlobalAlert({
      title,
      subtitle,
      type,
      iconType,
      buttonText,
      onButtonClick,
      ...options,
    }),
  );
  return action.payload.id;
};

/**
 * Payment Status Modal Types
 */
export const PAYMENT_STATUS = {
  PENDING: "pending",
  SUCCESS: "success",
  ERROR: "error",
};

/**
 * Show a payment status modal and alert
 * @param {string} status - The payment status (use PAYMENT_STATUS constants)
 * @param {object} options - Additional options (optional)
 * @returns {object} Object containing the alert ID and modal component info
 */
export const showPaymentStatusModal = (status, options = {}) => {
  let alertId;

  // Set default messages based on status
  const messages = {
    [PAYMENT_STATUS.PENDING]: {
      title: "Payment In Progress",
      subtitle:
        "Your transfer is being processed. We'll notify you when it's confirmed.",
    },
    [PAYMENT_STATUS.SUCCESS]: {
      title: "Payment Successful",
      subtitle: "Your payment has been processed successfully.",
    },
    [PAYMENT_STATUS.ERROR]: {
      title: "Payment Failed",
      subtitle:
        "There was an issue processing your transfer. Please check your bank details and try again.",
    },
  };

  const title = options.title || messages[status]?.title || "";
  const subtitle = options.subtitle || messages[status]?.subtitle || "";

  // Show appropriate alert based on status
  switch (status) {
    case PAYMENT_STATUS.PENDING:
      alertId = showPending(title, subtitle, { autoHide: false, ...options });
      break;
    case PAYMENT_STATUS.SUCCESS:
      alertId = showSuccess(title, subtitle, options);
      break;
    case PAYMENT_STATUS.ERROR:
      alertId = showError(title, subtitle, options);
      break;
    default:
      alertId = showInfo(title, subtitle, options);
  }

  // Return modal component info and alert ID
  return {
    alertId,
    modalProps: {
      status: status === PAYMENT_STATUS.SUCCESS ? undefined : status,
      showModal: true,
    },
  };
};

/**
 * Show a payment invoice modal with an alert
 * @param {string} productName - The name of the product
 * @param {number} totalAmount - The total amount
 * @param {number} amountToPay - The amount to pay
 * @param {object} options - Additional options (optional)
 * @returns {object} Object containing the alert ID and modal component info
 */
export const showPaymentInvoiceModal = (
  productName,
  totalAmount,
  amountToPay,
  options = {},
) => {
  const title = options.title || `Complete your payment for ${productName}`;
  const subtitle =
    options.subtitle || "Please complete your payment to access this feature.";

  const alertId = showInfo(title, subtitle, options);

  return {
    alertId,
    modalProps: {
      productName,
      totalAmount,
      amountToPay,
      showModal: true,
    },
  };
};
