import React, { createContext, useState, useContext } from "react";

const MobileSidebarContext = createContext();

export const MobileSidebarProvider = ({ children }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeSubmenu, setActiveSubmenu] = useState(null);

  const openMobileMenu = () => setMobileMenuOpen(true);
  const closeMobileMenu = () => {
    setMobileMenuOpen(false);
    setActiveSubmenu(null);
  };

  const openSubmenu = (menuId) => setActiveSubmenu(menuId);
  const closeSubmenu = () => setActiveSubmenu(null);

  return (
    <MobileSidebarContext.Provider
      value={{
        mobileMenuOpen,
        activeSubmenu,
        openMobileMenu,
        closeMobileMenu,
        openSubmenu,
        closeSubmenu,
      }}
    >
      {children}
    </MobileSidebarContext.Provider>
  );
};

export const useMobileSidebar = () => useContext(MobileSidebarContext);

export default MobileSidebarContext;
