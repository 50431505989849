import Auth from "./Auth";
import User from "./user";
import Socket from "../ducks/socket";
import liveEquities from "../ducks/liveEquities";
import equities from "../ducks/equities";
import themeMode from "../ducks/themeMode";
import layoutMode from "../ducks/layoutMode";
import symbol from "../ducks/symbol";
import ats from "../ducks/atsEquities";
import darkflow from "../ducks/darkflow";
import marketStatus from "../ducks/marketStatus";
import orderBook from "../ducks/orderbook";
import darkflowTable from "../ducks/darkflowTable";
import historicalDarkflow from "../ducks/historicalDarkflow";
import streams from "../ducks/pastStreams";
import addUsername from "../ducks/addUsername";
import radarToken from "../ducks/radarToken";
import sidebarOpen from "../ducks/sidebarOpen";
import lastPrice from "../ducks/polyLastPrice";
import optionChain from "../ducks/optionChain";
import searchSymbols from "../ducks/search";
import optionExpiry from "../ducks/optionExpirations";
// import earningsCalendar from "../ducks/earningsCalendar"
import optionSparkline from "../ducks/optionSparklines";
import popularStocks from "../ducks/popularStocks";
import dxFeedSocket from "../ducks/dxFeedSocket";
import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import newLiveOptions from "../ducks/newLiveOptions";
import companyData from "../ducks/companyData";
import exclusiveMembers from "../ducks/exclusiveMembers";
import swingTrades from "../ducks/swingTrades";
import counter from "../ducks/counter";
import candleSetter from "../ducks/defaultCandles";
import nameChange from "../ducks/changeName";
import updateLayout from "../ducks/updateLayout";
import updateFields from "../ducks/updateFields";
import userSettings from "../ducks/userSettings";
import modalClosed from "../ducks/modalClosed";
import swingTradesOptions from "../ducks/swingTradesOptions";
import optionAlertDates from "../ducks/getOptionAlertDates";
import startEnginePoll from "../ducks/startEnginePoll";
import chatParticipants from "../ducks/chatParticipants";
import hideLiveChat from "../ducks/hideLiveChat";
import EquitiesSocket from "../ducks/equitiesSocket";
import docusign from "../ducks/docusigned";
import signedAgreement from "../ducks/signedAgreement";
import tradeChat from "../ducks/tradeChat";
import updateTradeStatus from "../ducks/updateTradeStatus";
import swingSuggestions from "../ducks/swingSuggestions";
import updateProfile from "../ducks/updateProfile";
import runJourney from "../ducks/runJourney";
import contactForm from "../ducks/contactForm";
import sparklineData from "../ducks/sparklineData";
import topPicks from "../ducks/topPicks";
import widgetTicker from "../ducks/widgetTicker";
import wealthNews from "../ducks/wealthNews";
import wealthPortfolio from "../ducks/wealthPortfolio";
import wealthPerformance from "../ducks/wealthPerformance";
import portfolioTicker from "../ducks/portfolioTicker";
import portfolioSubscribe from "../ducks/portfolioSubscription";
import snackbar from "../ducks/snackbar";
import liteStockRecs from "../ducks/liteStockRecs";
import userPortfolio from "../ducks/userPortfolio";
import leaderboard from "../ducks/leaderboard";
import plaid from "../ducks/plaid";
import globalAlerts from "../ducks/globalAlerts";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: Auth,
    user: User,
    liveEquities,
    Socket,
    equities,
    themeMode,
    layoutMode,
    symbol,
    ats,
    darkflow,
    darkflowTable,
    marketStatus,
    orderBook,
    historicalDarkflow,
    streams,
    addUsername,
    radarToken,
    sidebarOpen,
    lastPrice,
    optionChain,
    searchSymbols,
    optionExpiry,
    // earningsCalendar,
    optionSparkline,
    popularStocks,
    dxFeedSocket,
    newLiveOptions,
    docusign,
    signedAgreement,
    companyData,
    exclusiveMembers,
    swingTrades,
    counter,
    candleSetter,
    nameChange,
    updateLayout,
    updateFields,
    userSettings,
    modalClosed,
    swingTradesOptions,
    optionAlertDates,
    startEnginePoll,
    chatParticipants,
    hideLiveChat,
    EquitiesSocket,
    tradeChat,
    updateTradeStatus,
    swingSuggestions,
    updateProfile,
    runJourney,
    contactForm,
    sparklineData,
    topPicks,
    widgetTicker,
    wealthNews,
    wealthPortfolio,
    wealthPerformance,
    portfolioTicker,
    portfolioSubscribe,
    snackbar,
    liteStockRecs,
    userPortfolio,
    leaderboard,
    plaid,
    globalAlerts,
  });
export default createRootReducer;
