import React, { useMemo, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import moment from "moment";
import { useAuth } from "../../contexts/Auth";
import BeautifulSkeleton from "../common/Skeletons/BeautifulSkeleton";
import { usePlaidLink } from "react-plaid-link";
import { isNull } from "lodash";
import PlaidStatusModal from "../Plaid/components/PlaidStatusModal";
import PlaidInvoiceModal from "../Plaid/components/PlaidInvoiceModal";
import {
  initiateTransfer,
  completeTransfer,
  fetchTransfers,
  fetchBills,
  getTransferData,
} from "../../appRedux/ducks/plaid";
import {
  hideAlert,
  showPaymentStatusModal,
  PAYMENT_STATUS,
} from "../../utils/alertUtils";

import WSLiteLogo from "../../assets/images/wealth-series-lite/logo.png";
import WSLiteLogoDark from "../../assets/images/wealth-series-lite/logo-white.png";
import WSLiteSignupFrameLight from "../../assets/images/wealth-series-lite/frame.png";
import WSLiteSignupFrameDark from "../../assets/images/wealth-series-lite/frame-white.png";
import AssistantRoundedLight from "../../assets/images/wealth-series-lite/AssistantRounded.png";
import AssistantRoundedDark from "../../assets/images/wealth-series-lite/AssistantRounded-white.png";
import StreamRoundedLight from "../../assets/images/wealth-series-lite/StreamRounded.png";
import StreamRoundedDark from "../../assets/images/wealth-series-lite/StreamRounded-white.png";
import ChatRoundedLight from "../../assets/images/wealth-series-lite/ChatRounded.png";
import ChatRoundedDark from "../../assets/images/wealth-series-lite/ChatRounded-white.png";

import "./LiteSignUp.scss";
import { Modal } from "@material-ui/core";
import ContactForm from "../EmeraldSignup/ContactForm";
import { useHistory } from "react-router-dom";

const featureData = [
  {
    imgLight: AssistantRoundedLight,
    imgDark: AssistantRoundedDark,
    title: "AI Driven Stock Picks",
    subtitle:
      "Get smarter insights instantly. Explore stock strategies crafted by advanced intelligence to match your unique goals.",
  },
  {
    imgLight: StreamRoundedLight,
    imgDark: StreamRoundedDark,
    title: "Interactive Livestreams",
    subtitle:
      "Engage in real-time discussions. Learn from live expert insights and dynamic stock analysis tailored to investors.",
  },
  {
    imgLight: ChatRoundedLight,
    imgDark: ChatRoundedDark,
    title: "Telegram Trade Alerts",
    subtitle:
      "Stay ahead of the curve with convenient biweekly updates, delivered on-the-go to keep you informed wherever you are.",
  },
];

const WealthSeriesLiteSignUp = ({
  themeMode,
  userSettings,
  plaidState,
  initTransfer,
  completeTransfer,
  fetchTransfers,
  fetchBills,
}) => {
  const { loading, user, userData, fetchUser, hasWealthSeriesLite } = useAuth();
  const [open, setOpen] = useState(false);
  const name = userData?.email || user?.email;
  const history = useHistory();

  // Plaid states
  const alertIdRef = useRef(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [statusModalProps, setStatusModalProps] = useState({});
  const [showInvoiceModal, setShowInvoiceModal] = useState(false);
  const [invoiceModalProps, setInvoiceModalProps] = useState({});
  const redirectTimerRef = useRef(null);

  const currentBill = useMemo(
    () =>
      plaidState?.bills?.find(
        (bill) =>
          bill.product_type_slug === "wealth_series_lite" &&
          isNull(bill.transfer_status),
      ),
    [plaidState?.bills],
  );

  // Detect partial payment scenario where one bill is paid but another remains unpaid
  const hasPartialPayment = useMemo(() => {
    if (!plaidState?.bills) return false;

    // Group bills by invoice_id
    const billsByInvoice = {};
    plaidState.bills.forEach((bill) => {
      if (bill.product_type_slug === "wealth_series_lite") {
        if (!billsByInvoice[bill.invoice_id]) {
          billsByInvoice[bill.invoice_id] = [];
        }
        billsByInvoice[bill.invoice_id].push(bill);
      }
    });

    // Check for invoices with both settled and null transfer_status bills
    return Object.values(billsByInvoice).some(
      (bills) =>
        bills.length > 1 &&
        bills.some(
          (bill) =>
            bill.transfer_status === "settled" ||
            bill.transfer_status === "funds_available",
        ) &&
        bills.some((bill) => bill.transfer_status === null),
    );
  }, [plaidState?.bills]);

  const isPendingPayment = useMemo(
    () =>
      plaidState?.bills?.some(
        (bill) =>
          bill.product_type_slug === "wealth_series_lite" &&
          (bill.transfer_status === "pending" ||
            bill.transfer_status === "posted"),
      ),
    [plaidState?.bills],
  );

  const isSuccessfulPayment = useMemo(
    () =>
      plaidState?.bills?.some(
        (bill) =>
          bill.product_type_slug === "wealth_series_lite" &&
          (bill.transfer_status === "settled" ||
            bill.transfer_status === "funds_available"),
      ),
    [plaidState?.bills],
  );

  const isFailedPayment = useMemo(
    () =>
      plaidState?.bills?.some(
        (bill) =>
          bill.product_type_slug === "wealth_series_lite" &&
          (bill.transfer_status === "failed" ||
            bill.transfer_status === "cancelled" ||
            bill.transfer_status === "returned"),
      ),
    [plaidState?.bills],
  );

  // Plaid success callback
  async function onPlaidSuccess(public_token) {
    // Use the Redux action to complete the transfer
    const transferData = getTransferData();
    completeTransfer(public_token, transferData.transfer_id);
    // Fetch user data to update access rights
    await fetchUser();
  }

  // Initialize Plaid Link
  const { open: openPlaidLink, ready } = usePlaidLink({
    token: plaidState?.transfer?.link,
    onSuccess: onPlaidSuccess,
  });

  // Effect to automatically open Plaid when token is available and ready
  useEffect(() => {
    if (plaidState?.transfer && ready) {
      openPlaidLink();
    }
  }, [plaidState?.transfer, ready, openPlaidLink]);

  // Fetch accounts and bill data on component mount
  useEffect(() => {
    if (fetchTransfers && fetchBills) {
      fetchTransfers();
      fetchBills();
    }
  }, [fetchTransfers, fetchBills]);

  // Function to determine the redirect path based on access rights
  const getRedirectPath = () => {
    if (hasWealthSeriesLite) {
      return "/wealth-series-lite-onboarding";
    }
    return "/wealth-series-lite-signup";
  };

  // Effect to redirect if user already has access
  useEffect(() => {
    if (loading) return;
    if (hasWealthSeriesLite) {
      const redirectPath = getRedirectPath();
      history.push(redirectPath);
    }
  }, [loading, hasWealthSeriesLite]);

  // Effect to show appropriate payment status alerts and modals
  useEffect(() => {
    // Clean up previous alert if exists
    if (alertIdRef.current) {
      hideAlert(alertIdRef.current);
      alertIdRef.current = null;
    }

    // Reset modal states
    setShowStatusModal(false);
    setShowInvoiceModal(false);

    // If there's a current bill (transfer_status is null), don't show any alerts
    // This takes precedence over failed payment alerts
    if (currentBill) {
      return;
    }

    // Show appropriate alert and modal based on payment status
    if (isPendingPayment) {
      const { alertId, modalProps } = showPaymentStatusModal(
        PAYMENT_STATUS.PENDING,
        {
          title: "Payment in Progress",
          subtitle:
            "Payment Initiated. Your transfer is being processed. We'll notify you when it's confirmed.",
          autoHide: true,
        },
      );
      alertIdRef.current = alertId;
      setStatusModalProps({
        ...modalProps,
        status: "pending",
      });
      setShowStatusModal(true);
    } else if (isSuccessfulPayment && !hasPartialPayment) {
      const { alertId } = showPaymentStatusModal(PAYMENT_STATUS.SUCCESS, {
        title: "Payment Successful",
        subtitle:
          "Your payment has been processed successfully. Redirecting you in 5 seconds...",
      });
      alertIdRef.current = alertId;

      // Clear any existing redirect timer
      if (redirectTimerRef.current) {
        clearTimeout(redirectTimerRef.current);
      }

      // Set up a new redirect timer for 5 seconds
      redirectTimerRef.current = setTimeout(() => {
        // First fetch latest user data to ensure feature flags are up to date
        fetchUser().then(() => {
          const redirectPath = getRedirectPath();
          history.push(redirectPath);
        });
      }, 5000);
    } else if (hasPartialPayment) {
      // For partial payment scenario, show a different message
      const { alertId } = showPaymentStatusModal(PAYMENT_STATUS.PENDING, {
        title: "Partial Payment Received",
        subtitle:
          "We've received your initial payment. Please complete the remaining balance to access the program.",
        autoHide: true,
      });
      alertIdRef.current = alertId;
    } else if (isFailedPayment) {
      const { alertId, modalProps } = showPaymentStatusModal(
        PAYMENT_STATUS.ERROR,
        {
          title: "Payment Failed",
          subtitle:
            "Payment Failed. There was an issue processing your transfer. Please contact us to try again.",
        },
      );
      alertIdRef.current = alertId;
      setStatusModalProps({
        ...modalProps,
        status: "error",
      });
      setShowStatusModal(true);
    }

    // Clean up the alert and timer when the component unmounts or when dependency changes
    return () => {
      if (alertIdRef.current) {
        hideAlert(alertIdRef.current);
      }

      if (redirectTimerRef.current) {
        clearTimeout(redirectTimerRef.current);
      }
    };
  }, [
    isPendingPayment,
    isSuccessfulPayment,
    isFailedPayment,
    hasPartialPayment,
    currentBill,
  ]);

  const hasSubmitted = useMemo(() => {
    const submitDate = userSettings?.wsLiteContact;
    const weekSinceSubmit = moment(submitDate).add(1, "week");
    const today = moment();
    return submitDate ? today.isBefore(weekSinceSubmit) : false;
  }, [userSettings, loading]);

  const openModal = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Function to determine the button text and state based on payment status
  const getButtonState = () => {
    // Always prioritize showing Pay button if there's a current bill
    if (currentBill) {
      return {
        text: "Pay",
        disabled: false,
        onClick: () => {
          setInvoiceModalProps({
            productName: "Wealth Series Lite",
            totalAmount: currentBill.sales_price,
            amountToPay: currentBill.transfer_amount,
          });
          setShowInvoiceModal(true);
        },
        className: "",
      };
    }

    if (isPendingPayment) {
      return {
        text: "Payment Processing",
        disabled: true,
        className: "processing",
      };
    } else if (isSuccessfulPayment && !hasPartialPayment) {
      return {
        text: "Access Program",
        disabled: false,
        onClick: () => {
          const redirectPath = getRedirectPath();
          history.push(redirectPath);
        },
        className: "success",
      };
    } else if (isFailedPayment) {
      return {
        text: "Contact Sales",
        disabled: false,
        onClick: openModal,
        className: "error",
      };
    } else if (hasPartialPayment) {
      return {
        text: "Pay Balance",
        disabled: false,
        onClick: () => {
          // Show invoice modal directly for the remaining bill
          const remainingBill = currentBill;
          if (remainingBill) {
            setInvoiceModalProps({
              productName: "Wealth Series Lite",
              totalAmount: remainingBill.sales_price,
              amountToPay: remainingBill.transfer_amount,
              isPartialPayment: true,
              paidAmount: remainingBill.paid,
            });
            setShowInvoiceModal(true);
          }
        },
        className: "partial-payment",
      };
    } else if (hasSubmitted) {
      return {
        text: "Access Requested",
        disabled: true,
        className: "",
      };
    } else {
      return {
        text: "Schedule Call",
        disabled: false,
        onClick: openModal,
        className: "",
      };
    }
  };

  return (
    <div className="ws-lite-signup">
      <div className="w-full">
        <div className="ws-lite-signup__header">
          <div className="ws-lite-signup__header__left">
            <img
              src={themeMode === "light" ? WSLiteLogo : WSLiteLogoDark}
              alt="Wealth Series Lite"
              className="ws-lite-signup__logo"
            />
            <div className="ws-lite-signup__header__title">
              <div className="ws-lite-signup__title">
                Smart, Simple AI Stock
              </div>
              <div className="ws-lite-signup__title">Recommendations</div>
            </div>
            <div className="ws-lite-signup__header__subtitle">
              Powered by four distinct approaches, our AI highlights a range of
              stocks for every type of investor. Discover new opportunities at a
              glance and quickly find the strategy that matches your style.
            </div>
            {loading ? (
              <BeautifulSkeleton
                className="no-transform-scale"
                width={192}
                height={43}
              />
            ) : (
              <>
                {(() => {
                  const buttonState = getButtonState();
                  return (
                    <Button
                      variant="contained"
                      color="primary"
                      className={`ws-lite-signup__header__button ${buttonState.className}`}
                      onClick={buttonState.onClick}
                      disabled={buttonState.disabled}
                    >
                      {buttonState.text}
                    </Button>
                  );
                })()}
              </>
            )}
          </div>
          <div className="ws-lite-signup__header__right">
            <div className="ws-lite-signup__gradient-dot" />
            <img
              src={
                themeMode === "light"
                  ? WSLiteSignupFrameLight
                  : WSLiteSignupFrameDark
              }
              alt="Wealth Series Lite"
              className="ws-lite-signup__frame"
            />
          </div>
        </div>
        <div className="ws-lite-signup__content">
          {featureData.map(({ imgLight, imgDark, title, subtitle }) => (
            <div className="ws-lite-signup__item" key={title}>
              <img
                src={themeMode === "light" ? imgLight : imgDark}
                alt={title}
                className="ws-lite-signup__item__img"
              />
              <div className="ws-lite-signup__item_content">
                <div className="ws-lite-signup__item__title">{title}</div>
                <div className="ws-lite-signup__item__subtitle">{subtitle}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="ws-lite-signup__footer">
        This website provides AI-powered insights for informational purposes
        only. Please use at your own discretion, and consult a qualified
        financial advisor for personalized guidance.
      </div>
      <Modal open={open} onClose={handleClose}>
        <ContactForm product_type="WEALTH SERIES LITE" onClose={handleClose} />
      </Modal>

      {/* Payment Status Modal */}
      {showStatusModal && (
        <PlaidStatusModal
          status={statusModalProps.status}
          onClose={() => setShowStatusModal(false)}
          onAction={() => {
            if (statusModalProps.status === "success") {
              fetchUser();
            } else if (statusModalProps.status === "error") {
              openModal();
            }
            setShowStatusModal(false);
          }}
        />
      )}

      {/* Payment Invoice Modal */}
      {showInvoiceModal && (
        <PlaidInvoiceModal
          productName={invoiceModalProps.productName}
          totalAmount={invoiceModalProps.totalAmount}
          amountToPay={invoiceModalProps.amountToPay}
          isPartialPayment={invoiceModalProps.isPartialPayment}
          paidAmount={invoiceModalProps.paidAmount}
          onClose={() => setShowInvoiceModal(false)}
          onAction={() => {
            // Initiate the transfer using the failed bill's ID if available
            const billToUse = currentBill;
            if (billToUse) {
              initTransfer(billToUse.bill_id, name, null);
              setShowInvoiceModal(false);
            }
          }}
        />
      )}
    </div>
  );
};

const stateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
  userSettings: state.userSettings.userSettings,
  plaidState: state.plaid,
});

const dispatchToProps = (dispatch) => ({
  initTransfer: (billId, legalName, accountId) =>
    dispatch(initiateTransfer(billId, legalName, accountId)),
  completeTransfer: (publicToken, transferId) =>
    dispatch(completeTransfer(publicToken, transferId)),
  fetchTransfers: () => dispatch(fetchTransfers()),
  fetchBills: () => dispatch(fetchBills()),
});

export default connect(stateToProps, dispatchToProps)(WealthSeriesLiteSignUp);
