import React from "react";
import "./SimpleSubmenu.scss";

const SimpleSubmenu = ({
  items,
  isMobile = false,
  onItemClick,
  activeSubItemPath,
}) => {
  const handleItemClick = (item) => {
    if (item.mailTo) {
      // Handle mailto links
      window.location.href = item.mailTo;
    } else if (item.onClick) {
      // Handle custom onClick functions
      item.onClick();
    } else if (onItemClick) {
      // Default navigation behavior
      onItemClick(item);
    }
  };

  return (
    <div className={`simple-submenu-items ${isMobile ? "mobile" : ""}`}>
      {items.map((item) => {
        const isActive = activeSubItemPath === item.path;
        return (
          <div
            key={item.id}
            className={`simple-submenu-item ${isActive ? "active" : ""}`}
            onClick={() => handleItemClick(item)}
          >
            <span>{item.label}</span>
            {item.isNew && <span className="new-badge">New</span>}
          </div>
        );
      })}
    </div>
  );
};

export default SimpleSubmenu;
