import React from "react";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import clsx from "clsx";
import { connect } from "react-redux";
import "./themeToggle.scss";
import { themeSetter } from "../../../../appRedux/ducks/themeMode";

const ThemeToggle = ({
  className,
  themeMode,
  themeToggle,
  collapsed = false,
}) => {
  const toggleTheme = () => {
    const newTheme = themeMode === "light" ? "dark" : "light";
    themeToggle(newTheme);
  };

  // If sidebar is collapsed, show only a single icon button
  if (collapsed) {
    return (
      <button
        onClick={toggleTheme}
        className="theme-toggle-collapsed"
        aria-label={
          themeMode === "light" ? "Switch to dark mode" : "Switch to light mode"
        }
      >
        {themeMode === "light" ? (
          <LightModeIcon fontSize="small" />
        ) : (
          <DarkModeIcon fontSize="small" />
        )}
      </button>
    );
  }

  // Regular expanded toggle with both buttons
  return (
    <div
      className={clsx("theme-toggle-container", className)}
      data-theme={themeMode}
    >
      <button
        onClick={() => toggleTheme("light")}
        className={clsx(
          "theme-toggle-button",
          themeMode === "light" ? "active" : "inactive",
        )}
        aria-label="Light mode"
      >
        <LightModeIcon fontSize="small" />
      </button>
      <button
        onClick={() => toggleTheme("dark")}
        className={clsx(
          "theme-toggle-button",
          themeMode === "dark" ? "active" : "inactive",
        )}
        aria-label="Dark mode"
      >
        <DarkModeIcon fontSize="small" />
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  themeMode: state.themeMode.themeMode,
});

const mapDispatchToProps = (dispatch) => ({
  themeToggle: (themeMode) => dispatch(themeSetter(themeMode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThemeToggle);
