/* eslint-disable */
import React, { useRef } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
import { makeStyles } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import { IconButton } from "@mui/material";
const styles = {
  "input-label": {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "100%",
    color: "red",
  },

  input: {
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#E5E5E5",
    },
  },
};
const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiChip-sizeMedium": {
      display: "none",
    },
    "&::placeholder": {
      textOverflow: "ellipsis !important",
      color: "#E5E5E5",
    },
    "& .MuiTextField-root": {
      margin: "0!important",
    },

    "& .MuiOutlinedInput-input": {
      fontFamily: "Poppins",
      position: "relative",
      top: "-6px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
    },
    "& .MuiOutlinedInput-root": {
      backgroundColor: theme.palette.primary.searchBg,
      borderRadius: "16px",
      height: "40px",
      width: "100%",
      paddingRight: "35px!important",
    },
    "&  .MuiAutocomplete-endAdornment": {
      display: "none",
      visibility: "none",
    },
    "& label.Mui-focused": {
      color: "grey",
    },
    "& label": {
      display: "none",
      position: "relative",
      top: "32px",
      // color: "rgba(162, 163, 165, 1)",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
    "& fieldset": {
      borderColor: "grey",
    },
    "&:hover fieldset": {
      borderColor: "white",
    },
    "&.Mui-focused fieldset": {
      borderColor: "white",
    },
  },
}));

function GridTickerSearch({
  options,
  loading,
  symbol,
  setSymbol,
  customMinWidth,
  onAddSymbol,
  disabled,
  setSelectedTicker,
}) {
  const classes = useStyles();
  // Add local state to track and reset selection
  const [key, setKey] = React.useState(0);

  // Function to handle selection and reset component
  const handleSelection = (event, values) => {
    console.log("Selection values:", values);

    if (values && values.length > 0 && values[0].ticker) {
      const selectedTicker = values[0].ticker;
      console.log("Selected ticker:", selectedTicker);

      // Update selected ticker if prop exists
      if (setSelectedTicker) {
        setSelectedTicker(selectedTicker);
      }

      // Call the add symbol function with the selected ticker
      onAddSymbol(selectedTicker);

      // Reset the input
      setSymbol("");

      // Force a complete reset of the Autocomplete by changing its key
      setTimeout(() => {
        setKey((prevKey) => prevKey + 1);
      }, 0);
    }
  };

  return (
    <div className="option-ticker-search">
      <div className="option-ticker-search-input">
        <Autocomplete
          key={key} // Add key to force complete re-render when changed
          disabled={disabled}
          className={classes.root}
          style={{
            position: "relative",
            width: "100%",
            minWidth: customMinWidth ? `${customMinWidth}px` : "290px",
          }}
          id="highlights-demo"
          options={options}
          loading={loading}
          disablePortal={true}
          multiple={true}
          noOptionsText={"No Matching Tickers Found"}
          getOptionLabel={(option) => option.ticker + " - " + option.name}
          onChange={handleSelection}
          renderInput={(params) => (
            <TextField
              {...params}
              disabled={disabled}
              onChange={(e) => {
                setSymbol(e.target.value);
              }}
              value={symbol}
              InputProps={{
                ...params.InputProps,
                classes: { input: styles["input"] },
                endAdornment: (
                  <React.Fragment>
                    <span
                      style={{
                        position: "relative",
                        left: "20px",
                        top: "-8px",
                      }}
                    >
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.4121 14.4121L20 20"
                          stroke="#E5E5E5"
                          strokeWidth="2"
                          strokeLinecap="square"
                        />
                        <path
                          d="M10 16C13.3137 16 16 13.3137 16 10C16 6.68629 13.3137 4 10 4C6.68629 4 4 6.68629 4 10C4 13.3137 6.68629 16 10 16Z"
                          stroke="#E5E5E5"
                          strokeWidth="2"
                          strokeLinecap="square"
                        />
                      </svg>
                    </span>
                  </React.Fragment>
                ),
              }}
              style={{ border: "1px solid #E5E5E5", borderRadius: "16px" }}
              margin="normal"
              placeholder="Search Tickers..."
            />
          )}
          renderOption={(props, option, { inputValue }) => {
            const matches = match(
              option.ticker + " - " + option.name,
              inputValue,
            );
            const parts = parse(option.ticker + " - " + option.name, matches);
            return (
              <li className="live-option-input-option" {...props}>
                <div>
                  {parts.map((part, index) => (
                    <span
                      key={index}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }}
        />
      </div>
    </div>
  );
}
export default GridTickerSearch;
