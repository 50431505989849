import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { hideGlobalAlert } from "../../appRedux/ducks/globalAlerts";
import PlaidAlert from "../Plaid/components/PlaidAlert";
import "./GlobalAlerts.scss";

const GlobalAlerts = () => {
  const dispatch = useDispatch();
  const { alerts } = useSelector((state) => state.globalAlerts);

  if (!alerts || alerts.length === 0) {
    return null;
  }

  return (
    <div className="global-alerts-container">
      {alerts.map((alert) => (
        <div key={alert.id} className="global-alert-wrapper">
          <PlaidAlert
            title={alert.title}
            subtitle={alert.subtitle}
            type={alert.type}
            iconType={alert.iconType}
            buttonText={alert.buttonText}
            onButtonClick={() => {
              if (alert.onButtonClick) {
                alert.onButtonClick();
              }
              if (alert.closeOnButtonClick !== false) {
                dispatch(hideGlobalAlert(alert.id));
              }
            }}
          />
          {alert.autoHide !== false && (
            <div
              className="global-alert-close"
              onClick={() => dispatch(hideGlobalAlert(alert.id))}
            >
              &times;
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default GlobalAlerts;
