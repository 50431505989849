import React from "react";

import PendingIcon from "@mui/icons-material/Pending";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";

import Button from "@mui/material/Button";

import "./PlaidAlert.scss";

const PlaidAlert = ({
  title, // title of alert
  subtitle = "", // subtitle of alert
  type = "primary", // type of alert (primary, error, success)
  iconType = "info", // icon type of alert (pending, error, success, info)
  buttonText, // button text for alert ( if button text, show button )
  onButtonClick, // on button click event
}) => {
  return (
    <div className={`plaid-integration-alert ${type}-alert`}>
      <div className="plaid-integration-alert-body">
        <div className="plaid-integration-alert-icon">
          {iconType === "pending" && <PendingIcon />}
          {iconType === "error" && <CancelIcon />}
          {iconType === "success" && <CheckCircleIcon />}
          {iconType === "info" && <InfoIcon />}
        </div>

        <div className="plaid-integration-alert-content">
          <div className="plaid-integration-alert-title">{title}</div>
          {subtitle && (
            <div className="plaid-integration-alert-subtitle">{subtitle}</div>
          )}
        </div>
      </div>

      {buttonText && (
        <Button
          className="plaid-integration-alert-button"
          variant="contained"
          onClick={onButtonClick}
        >
          {buttonText}
        </Button>
      )}
    </div>
  );
};

export default PlaidAlert;
