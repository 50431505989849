import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";

// Constants
const PLAID_INITIATE_TRANSFER = "plaid/INITIATE_TRANSFER";
const PLAID_INITIATE_TRANSFER_SUCCESS = "plaid/INITIATE_TRANSFER_SUCCESS";
const PLAID_INITIATE_TRANSFER_FAILURE = "plaid/INITIATE_TRANSFER_FAILURE";

const PLAID_COMPLETE_TRANSFER = "plaid/COMPLETE_TRANSFER";
const PLAID_COMPLETE_TRANSFER_SUCCESS = "plaid/COMPLETE_TRANSFER_SUCCESS";
const PLAID_COMPLETE_TRANSFER_FAILURE = "plaid/COMPLETE_TRANSFER_FAILURE";

const PLAID_FETCH_ACCOUNTS = "plaid/FETCH_ACCOUNTS";
const PLAID_FETCH_ACCOUNTS_SUCCESS = "plaid/FETCH_ACCOUNTS_SUCCESS";
const PLAID_FETCH_ACCOUNTS_FAILURE = "plaid/FETCH_ACCOUNTS_FAILURE";

const PLAID_FETCH_BILLS = "plaid/FETCH_BILLS";
const PLAID_FETCH_BILLS_SUCCESS = "plaid/FETCH_BILLS_SUCCESS";
const PLAID_FETCH_BILLS_FAILURE = "plaid/FETCH_BILLS_FAILURE";

const PLAID_RESET_TRANSFER = "plaid/RESET_TRANSFER";
const PLAID_SET_SELECTED_ACCOUNT = "plaid/SET_SELECTED_ACCOUNT";

// Store transfer data outside of Redux to persist between renders
let transferData = {};

// Initial State
const INIT_STATE = {
  transfer: null,
  transfers: [],
  bills: [],
  selectedAccountId: null,
  loading: {
    transfer: false,
    transfers: false,
    bills: false,
  },
  error: {
    transfer: null,
    transfers: null,
    bills: null,
  },
};

// Action Creators
export const initiateTransfer = (billId, legalName, accountId) => ({
  type: PLAID_INITIATE_TRANSFER,
  payload: { billId, legalName, accountId },
});

export const initiateTransferSuccess = (transfer) => ({
  type: PLAID_INITIATE_TRANSFER_SUCCESS,
  payload: transfer,
});

export const initiateTransferFailure = (error) => ({
  type: PLAID_INITIATE_TRANSFER_FAILURE,
  payload: error,
});

export const completeTransfer = (publicToken, transferId) => ({
  type: PLAID_COMPLETE_TRANSFER,
  payload: { publicToken, transferId },
});

export const completeTransferSuccess = () => ({
  type: PLAID_COMPLETE_TRANSFER_SUCCESS,
});

export const completeTransferFailure = (error) => ({
  type: PLAID_COMPLETE_TRANSFER_FAILURE,
  payload: error,
});

export const fetchTransfers = () => ({
  type: PLAID_FETCH_ACCOUNTS,
});

export const fetchTransfersSuccess = (transfers) => ({
  type: PLAID_FETCH_ACCOUNTS_SUCCESS,
  payload: transfers,
});

export const fetchTransfersFailure = (error) => ({
  type: PLAID_FETCH_ACCOUNTS_FAILURE,
  payload: error,
});

export const fetchBills = () => ({
  type: PLAID_FETCH_BILLS,
});

export const fetchBillsSuccess = (bills) => ({
  type: PLAID_FETCH_BILLS_SUCCESS,
  payload: bills,
});

export const fetchBillsFailure = (error) => ({
  type: PLAID_FETCH_BILLS_FAILURE,
  payload: error,
});

export const resetTransfer = () => ({
  type: PLAID_RESET_TRANSFER,
});

export const setSelectedAccount = (accountId) => ({
  type: PLAID_SET_SELECTED_ACCOUNT,
  payload: accountId,
});

// Sagas
function* initiateTransferSaga(action) {
  const { billId, legalName, accountId } = action.payload;
  const apiUrl = process.env.REACT_APP_API_URL;

  try {
    const { data } = yield call(
      axios.post,
      `${apiUrl}/plaid/transfers/initiate`,
      {
        bill_id: billId,
        legal_name: legalName,
        account_id: accountId,
      },
      {
        withCredentials: true,
      },
    );

    // Store transfer data for later use
    transferData = data;

    yield put(initiateTransferSuccess(data));
  } catch (error) {
    console.error("Failed to initiate transfer:", error);
    yield put(
      initiateTransferFailure(error.message || "Failed to initiate transfer"),
    );
  }
}

function* completeTransferSaga(action) {
  const { publicToken, transferId } = action.payload;
  const apiUrl = process.env.REACT_APP_API_URL;

  try {
    yield call(
      axios.post,
      `${apiUrl}/plaid/transfers/complete`,
      {
        public_token: publicToken,
        transfer_id: transferId || transferData.transfer_id,
      },
      {
        withCredentials: true,
      },
    );

    yield put(completeTransferSuccess());

    // Fetch updated bills to get the new payment status
    yield put(fetchBills());
  } catch (error) {
    console.error("Failed to complete transfer:", error);
    yield put(
      completeTransferFailure(error.message || "Failed to complete transfer"),
    );
  }
}

function* fetchTransfersSaga() {
  const apiUrl = process.env.REACT_APP_API_URL;

  try {
    const { data } = yield call(axios.get, `${apiUrl}/plaid/transfers`, {
      withCredentials: true,
    });

    yield put(fetchTransfersSuccess(data));
  } catch (error) {
    console.error("Failed to fetch transfers:", error);
    yield put(
      fetchTransfersFailure(error.message || "Failed to fetch transfers"),
    );
  }
}

function* fetchBillsSaga() {
  const apiUrl = process.env.REACT_APP_API_URL;

  try {
    const { data } = yield call(axios.get, `${apiUrl}/invoices/bills`, {
      withCredentials: true,
    });

    yield put(fetchBillsSuccess(data));
  } catch (error) {
    console.error("Failed to fetch bills:", error);
    yield put(fetchBillsFailure(error.message || "Failed to fetch bills"));
  }
}

// Watchers
function* watchInitiateTransfer() {
  yield takeEvery(PLAID_INITIATE_TRANSFER, initiateTransferSaga);
}

function* watchCompleteTransfer() {
  yield takeEvery(PLAID_COMPLETE_TRANSFER, completeTransferSaga);
}

function* watchFetchAccounts() {
  yield takeEvery(PLAID_FETCH_ACCOUNTS, fetchTransfersSaga);
}

function* watchFetchBills() {
  yield takeEvery(PLAID_FETCH_BILLS, fetchBillsSaga);
}

// Root Saga
export function* saga() {
  yield all([
    fork(watchInitiateTransfer),
    fork(watchCompleteTransfer),
    fork(watchFetchAccounts),
    fork(watchFetchBills),
  ]);
}

// Reducer
const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PLAID_INITIATE_TRANSFER:
      return {
        ...state,
        loading: { ...state.loading, transfer: true },
        error: { ...state.error, transfer: null },
      };
    case PLAID_INITIATE_TRANSFER_SUCCESS:
      return {
        ...state,
        transfer: action.payload,
        loading: { ...state.loading, transfer: false },
      };
    case PLAID_INITIATE_TRANSFER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, transfer: false },
        error: { ...state.error, transfer: action.payload },
      };
    case PLAID_COMPLETE_TRANSFER:
      return {
        ...state,
        loading: { ...state.loading, transfer: true },
      };
    case PLAID_COMPLETE_TRANSFER_SUCCESS:
      return {
        ...state,
        transfer: null,
        loading: { ...state.loading, transfer: false },
      };
    case PLAID_COMPLETE_TRANSFER_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, transfer: false },
        error: { ...state.error, transfer: action.payload },
      };
    case PLAID_FETCH_ACCOUNTS:
      return {
        ...state,
        loading: { ...state.loading, transfers: true },
        error: { ...state.error, transfers: null },
      };
    case PLAID_FETCH_ACCOUNTS_SUCCESS:
      return {
        ...state,
        transfers: action.payload,
        loading: { ...state.loading, transfers: false },
      };
    case PLAID_FETCH_ACCOUNTS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, transfers: false },
        error: { ...state.error, transfers: action.payload },
      };
    case PLAID_FETCH_BILLS:
      return {
        ...state,
        loading: { ...state.loading, bills: true },
        error: { ...state.error, bills: null },
      };
    case PLAID_FETCH_BILLS_SUCCESS:
      return {
        ...state,
        bills: action.payload,
        loading: { ...state.loading, bills: false },
      };
    case PLAID_FETCH_BILLS_FAILURE:
      return {
        ...state,
        loading: { ...state.loading, bills: false },
        error: { ...state.error, bills: action.payload },
      };
    case PLAID_RESET_TRANSFER:
      return {
        ...state,
        transfer: null,
      };
    case PLAID_SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedAccountId: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;

// Helper functions for components
export const getTransferData = () => transferData;
